export const LANG_STRINGS = {
  giveFeedback: 'Beri Rating dan Ulasan',
  feedbackLowest: 'JELEK',
  feedbackHighest: 'SANGAT PUAS',
  satisfied: 'Apakah Anda puas terhadap pelayanan PasarPolis?',
  disclaimer: 'DISCLAIMER',
  surveyTimingError: 'Waktu survey hanya dapat dilakukan selama hari kerja dari jam 9 pagi sampai jam 3 sore',
  selectOptionToConductTheSurvey: 'Pilih opsi untuk melakukan survey kendaraan.',
  vehicleSurvey: 'Survey Kendaraan',
  vehicleSurveyOption: 'Opsi Survey Kendaraan',
  removeFilter: 'Hapus Filter',
  mutationType: 'Tipe Mutasi',
  seeGuide: 'Lihat Panduan',
  consist16Numbers: 'Terdiri dari 16 angka.',
  beingVerified: 'Sedang diverifikasi',
  accountNo: 'No. Rekening',
  addPhoto: 'Tambah Foto',
  policyPrintStatus: 'Status Cetak Polis',
  invalidPayment: 'Pembayaran tidak valid ',
  agreementTermsNConditions: 'Persetujuan Syarat dan Ketentuan',
  virtualAccountInformation: 'Informasi Virtual Account',
  viewEndorsementDetails: 'Lihat Rincian Endorsement',
  endorsementDetails: 'Rincian Endorsement',
  lackOfPolicyPay: 'Kekurangan Bayar Polis',
  endorsement: 'Endorsement',
  premiumChange: 'Perubahan Premi',
  paymentLinkSentNew: 'Link Pembayaran Terkirim',
  protectionTypeNew: 'Tipe Proteksi',
  lastTransactions: 'Transaksi Terakhir',
  additionalPremiumPaymentStatus: 'Status Pembayaran Premi Tambahan',
  policyInvoice: 'Invoice Polis',
  search: 'Cari',
  searchDownline: 'Cari nama downline',
  businessPartner: 'Business Partner',
  businessExecutive: 'Business Executive',
  option: 'Opsi',
  remindToCustomer: 'Ingatkan Pelanggan',
  notFilledYet: 'Belum diisi',
  notUploadedYet: 'Belum diunggah',
  notificationNotFound: 'Belum Ada Pemberitahuan',
  earlyAdditionalCoverage: 'Perlindungan Tambahan Awal',
  enterDiscount: 'Masukan Potongan Harga',
  sharePaymentLink: 'Bagikan Link Pembayaran',
  SNK: 'S&K',
  policy: 'Polis',
  popular: 'Terpopuler',
  latest: 'Terbaru',
  lowest: 'Terendah',
  highest: 'Tertinggi',
  changeGani: 'GANTI',
  exitInFilter: 'Sortir dan Filter',
  sortSearchResult: 'Sortir Hasil Pencarian',
  nameAndContactInfo: 'Nama dan Informasi Kontak',
  partnerApproval: 'Persetujuan Mitra',
  offersFound: 'penawaran ditemukan',
  endorsementInvoice: 'Invoice Endorsement',
  packageInsurance: 'Paket Asuransi',
  packageList: 'Daftar Paket',
  policyPrintingFees: 'Biaya Cetak Polis',
  photoUploadGuide: 'Panduan Unggah Foto',
  changeToPayLater: 'Ubah Ke Paylater',
  youCanUploadMoreThanOnePhoto: 'Anda dapat mengunggah foto lebih dari 1 (satu) sekaligus.',
  makeSurePhotoIsGood: 'Pastikan foto yang Anda pilih memiliki penerangan yang cukup dan jelas.',
  insuranceDetailSentToEmail: 'Detail paket asuransi telah berhasil dikirimkan ke email:',
  quotationShareMsg: 'Terima kasih telah memilih PasarPolis untuk pembelian produk asuransi Anda. Lengkapi dokumen dan lanjutkan pembayaran di XXX untuk mendapatkan perlindungan yang Anda inginkan',
  quotationSaveMsg: 'Quotation tersimpan dengan nama',
  offerNo: 'Nomor Penawaran',
  thankYou: 'Terima Kasih',
  return: 'Kembali',
  feedbackThanksNote: 'Telah memberikan rating dan ulasan terhadap pelayanan klaim kami.',
  youOfferHasBeenSent: 'Penawaran Anda telah terkirim.',
  locationSameProvince: 'Lokasi berada didalam 1 provinsi yang sama dengan device Anda',
  locationSameCity: 'Lokasi berada didalam 1 kota yang sama dengan device Anda',
  sameProvince: 'Lokasi Dalam 1 Provinsi',
  sameCity: 'Lokasi Dalam 1 Kota',
  within15kmOfDeviceLocation: 'Berjarak 15km dari lokasi device Anda',
  nearbyLocation: 'Lokasi Terdekat',
  paymentUploadTime: 'Waktu Unggah Pembayaran',
  headquarter: 'Kantor Pusat',
  writeAddrName: 'Tulis nama alamat cabang',
  selectBranchAddr: 'Pilih alamat cabang untuk proses penerbitan polis',
  branchAddressList: 'Daftar Alamat Cabang',
  addressToBranch: 'Ditujukan ke cabang',
  branch: 'Cabang',
  nameOfPolicyProviderOffice: 'Nama Kantor Penerbitan Polis',
  shareThisWithYourCustomer: 'Bagikan penawaran ini ke calon pelanggan Anda',
  policyPublishingOffice: 'Kantor Penerbitan Polis',
  offlinePayment7DayVerification: 'Pembayaran Offline ke pihak asuransi memerlukan proses verifikasi pembayaran 7 hari',
  onlinePayment1DayVerification: 'Pembayaran Online atau melalui Aplikasi memerlukan proses verifikasi pembayaran maksimal 1x24 jam',
  addressOfPolicyProviderOffice: 'Alamat Kantor Penerbitan Polis',
  importantInfo: 'Informasi Penting',
  yourCommissionMaxDisburseOnTheDate: 'Komisi/Poin Anda akan dicairkan maksimum tanggal', // need to change in UI
  ageDifferenceWhenMakingAndPaying: 'Usia tertanggung saat pembuatan polis berbeda saat pembayaran polis',
  fwdTransactionBenefit: 'Memberikan perlindungan kesehatan',
  insuredAlreadyHaveActivePolicy: 'Tertanggung dengan KTP/NIK sudah memiliki polis aktif',
  bmiNotValid: 'Produk tidak tersedia untuk tinggi dan berat badan Anda',
  ktpNikInvalid: 'KTP/NIK tidak valid',
  sendQuotationSendToCustomerRemark: 'Penawaran akan dikirim ke pelanggan sesuai data yang dimasukkan dan selanjutnya pelanggan akan melengkapi informasi kendaraan sebelum melakukan pembayaran.',
  sendQuotationRemarkFWD: 'Penawaran akan dikirim ke pemegang polis sesuai data yang dimasukkan dan selanjutnya pemegang polis akan melengkapi data secara keseluruhan sebelum melakukan pembayaran.',
  nikMustBe16digits: 'NIK harus 16 angka',
  idCardMustBe16Digits: 'KTP harus 16 angka',
  ktpNoCannotBeSame: 'Peserta bukan tertanggung. KTP keduanya harus berbeda!',
  ktpAndBirthCertiNotSame: 'Peserta bukan tertanggung. Nomor KTP dan Akta Lahir keduanya harus berbeda!',
  ktpAndNikCannotBeSame: 'Peserta bukan tertanggung. Nomor KTP dan NIK keduanya harus berbeda!',
  microHealth: 'Kesehatan Mikro',
  maxProtection: 'Perlindungan Maksimal',
  answer1: 'Jawaban',
  maximumThree: 'Maksimal 3',
  jobTypeOfInsured: 'Jenis Pekerjaan Tertanggung',
  isInsuredWorking: 'Apakah tertanggung bekerja',
  doesPolicyHolderWork: 'Apakah Peserta Bekerja',
  policyHolderDob: 'Tanggal Lahir Peserta',
  date: 'Tanggal',
  time: 'Jam',
  policyHolder1: 'Pemegang Polis',
  sorry: 'Mohon Maaf',
  gallery: 'Galeri',
  vehicleDamage: 'Cacat Bawaan',
  vehicleModificationPhotos: 'Foto Modifikasi Kendaraan',
  premiumHaveBeenChanged: 'Herga premi telah dopernarui',
  premiumCoverageHaveBeenChanged: 'Tanggal mulai polis dan Herga premi telah dopernarui',
  policyStartDateHasBeenChangedToCurrent: 'Tanggal mulai polis telah berganti menjadi tanggal hari ini.',
  partyNotEligible: 'Pihak Yang Diasuransikan tidak memenuhi syarat untuk melakukan pembelian produk ini karena tidak memenuhi prosedur kesehatan yang ditawarkan.',
  diseaseDiagnosisText: 'Gangguan atau cedera yang membutuhkan konsultasi yang berkelanjutan, rawat inap atau perawatan medis secara terus menerus selama 7 hari atau lebih (kecuali untuk flu, infeksi saluran pernafasan atas atau penyakit virus) atau apakah Kamu pernah menjalani pembedahan atau menjalani tes medis dengan hasil tidak normal (misalnya : tes darah atau urin, USG, CT scan, MRI, biopsi dll).',
  diseaseSymptomsText: 'Penyakit jantung, kanker, tekanan darah tinggi, kolesterol tinggi, stroke, epilepsi, diabetes, penyakit paru obstruktif kronik, asma, bronkitis berulang, tuberkulosis, gagal ginjal, gangguan fungsi ginjal, peningkatan enzim hati, sirosis hati, Hepatitis B atau C, batu dan peradangan di kantung empedu, penyalahgunaan alkohol atau obat terlarang.',
  diseaseSymptoms: 'Gejala Penyakit',
  diseaseDiagnosis: 'Diagnosis Penyakit',
  answerToThreeQuestionsAbove: 'Jawaban untuk ketiga pertanyaan di atas',
  height: 'Tinggi Badan (cm)',
  answer: 'Pertanyaan-Pertanyaan',
  weight: 'Berat Badan (kg)',
  forTheInsured: 'Untuk Pihak Yang Diasuransikan',
  overview: 'Tinjauan',
  viewAll: 'Lihat Semua',
  differentFromKTP: 'Berbeda dengan yang tertera di KTP',
  residenceAddress: 'Alamat Domisili',
  addressAsPerKTP: 'Alamat Sesuai KTP',
  file: 'File',
  stopIt: 'HENTIKAN',
  addressAsPerKK: 'Alamat Sesuai KK',
  insuredPartyDetails: 'Detil Pihak Yang Diasuransikan',
  completeness: 'Kelengkapan Data',
  fullNameAsPerBirthCertificate: 'Nama Lengkap Sesuai Akta Lahir',
  fullNameAsPerKK: 'Nama Lengkap Sesuai KK',
  fullNameAsPerKtp: 'Nama Lengkap Sesuai KTP',
  minAge18years: 'Usia peserta minimum 18 tahun',
  insuredAgeBetween30DaysTo55years: 'Usia tertanggung harus mulai dari 30 hari sampai 55 tahun',
  policyHolderAgeBetween18yearsTo55years: 'Usia tertanggung harus mulai dari 18 tahun sampai 55 tahun',
  roomFee: 'Biaya Kamar',
  sendOTPUsing: 'Kirim OTP menggunakan',
  healthQuestions: 'Pertanyaan Kesehatan',
  questions: 'Pertanyaan-Pertanyaan',
  annualBenifits: 'Manfaat pertahun',
  perYear: 'pertahun',
  healthJobsClassified1: 'Sering bekerja dengan mesin atau peralatan ringan dan di luar ruangan; (contoh: pekerja di percetakan, pekerja penatu, pekerja tekstil, pekerja elektronik, pekerja cleaning service, supir pribadi, dekorator interior, dll)',
  healthJobsClassified2: 'Bekerja dengan mesin berukuran besar dan di luar ruangan (contoh: mekanik, sopir kendaraan traktor/truk, kurir, penyelam profesional (kurang dari 40 m), fotografer pertambangan/udara, stunt man, instruktur kungfu, nelayan, angkatan bersenjata, kru pesawat, pekerja di udara, pembalap, jocky, pekerja di konstruksi, dll)',
  jobsThatAreClassifiedAsHighRisk: 'Pekerjaan yang termasuk dalam risiko tinggi di antaranya adalah:',
  participant: 'Peserta',
  insuredParty: 'Pihak Yang Diasuransikan',
  typeOfWork: 'Jenis Pekerjaan',
  relationWithInsured: 'Hubungan dengan Peserta',
  notWorking: 'Belum bekerja',
  completeTheFormBelow: 'Lengkapi formulir di bawah ini untuk merincikan detil dari polis yang akan dibuat. Bagian yang diberi tanda bintang (*) wajib diisi.',
  workingStatus: 'Status Bekerja',
  pullDownToRefresh: 'Tarik kebawah untuk merefresh status',
  cannotBeTrackedNotReachable: 'Tidak dapat dilacak karena lokasi tidak terjangkau',
  cannotBeTrackedDataUnavailable: 'Sedang menunggu kurir untuk mengambil/mengirimkan polis',
  trackDelivery: 'Lacak Pengiriman',
  receiptNumber: 'Nomor Resi',
  repeatProcess: 'Ulangi proses',
  notFound: 'Tidak ditemukan',
  activeMobileNumber: 'Nomor Handphone (Aktif)',
  addressUpdatedSuccessfully: 'Alamat berhasil diperbaharui',
  thereAreNoOffersForYourCriteria: 'Tidak ada penawaran berdasarkan kriteria yang Anda masukkan.',
  completeAddress: 'Alamat Lengkap',
  completeFormBelow: 'Lengkapi formulir di bawah ini. Bagian yang diberi tanda bintang (*) wajib diisi',
  contentNow: 'ISI SEKARANG',
  noDataYet: 'Belum ada data',
  changeAddress: 'ubah alamat',
  address1: 'Alamat',
  addressContents: 'ISI ALAMAT',
  addressDataNotComplete: 'Anda belum melengkapi data alamat pada profil Anda',
  fillYourAddress: 'Isi alamat Anda sekarang',
  marineCargoPeriod: '1x jalan (30 hari)',
  mvPeriod: '12 Bulan',
  additionalPackageList: 'List Paket Tambahan',
  pkgNotExistHealthBenifit: 'Maaf, belum ada produk asuransi yang sesuai dengan data Anda',
  pkgNotExistCargo: 'Maaf, belum ada produk asuransi yang sesuai dengan data cargo Anda',
  land: 'Darat',
  air: 'Udara',
  sea: 'Laut',
  offerSaved: 'Penawaran berhasil disimpan',
  agentDetails: 'Detil Agen',
  download: 'Unduh',
  buy: 'Beli',
  lossRatio: 'Loss Rasio',
  saveOffer: 'Simpan Penawaran',
  saveNew: 'Simpan',
  registerPolicy: 'Daftar Polis',
  uploadPaymentProof: 'Unggah Bukti Bayar',
  claimInsurance: 'Klaim Asuransi',
  renewalPolicy: 'Perpanjang Polis',
  showExpiredInsurance: 'Tampilkan Asuransi Kadaluwarsa saja',
  makeSureAllEnteredInfoIsCorrect: 'Pastikan semua informasi yang Anda masukkan sudah benar',
  registrationNumberIMO: 'Nomor Registrasi (IMO)',
  policyDetails: 'Detil Polis',
  changePolicy: 'Ubah Polis',
  careClaimPolis: 'Cara Klaim Polis',
  renewPolicyNow: 'Perpanjang Polis Sekarang',
  dataChecking: 'Pengecekan Data',
  insuredProvince: 'Provinsi Tertanggung',
  insuredCity: 'Kota Tertanggung',
  insuredAddress: 'Alamat Lengkap Tertanggung',
  insuredEmail: 'Email Tertanggung',
  insuredBusiness: 'Bisnis Tertanggung',
  insuredMobileNumber: 'Nomor Handphone Tertanggung',
  fullNameAccToInsuredKTP: 'Nama Lengkap Sesuai KTP Tertanggung',
  policyHolderData: 'Data Tertanggung',
  relationshipManager: 'Relationship Manager',
  invoice: 'Invoice',
  invoice1: 'INVOICE',
  partnerName: 'Nama Mitra',
  amount: 'Jumlah',
  detail: 'Detil',
  subTotalPremium: 'Sub Total Premium',
  completePaymentInXDaysOfInvoice: 'Silakan menyelesaikan pembayaran dalam kurun waktu XXX setelah invoice di terbitkan.',
  paymentCanBeThroughApp: 'Pembayaran dapat dilakukan melalui aplikasi Mitra dan akan dikenakan biaya layanan apabila melakukan pembayaran menggunakan CC dan Gopay. ',
  invoiceInvalid: 'Invoice sudah tidak valid',
  invoiceNotFound: 'Invoice tidak ditemukan',
  billAWB: 'Tagihan (AWB)',
  attachment: 'Lampiran',
  travelNo: 'Nomor Perjalanan',
  departureDate: 'Perkiraan Waktu Keberangkatan',
  estimatedDeliveryDate: 'Perkiraan Waktu Pengiriman Barang',
  provinceOfOrigin: 'Provinsi Asal Pengiriman',
  cityOfOrigin: 'Kota Asal Pengiriman',
  addressOfOrigin: 'Alamat Asal Pengiriman (Area Indonesia Saja)',
  provinceOfDestination: 'Provinsi Tujuan Pengiriman',
  cityOfDestination: 'Kota Tujuan Pengiriman',
  addressOfDestination: 'Alamat Tujuan Pengiriman (Area Indonesia Saja)',
  policyPeriodExpiredAt: 'Periode Polis akan habis per tanggal',
  countryFlag: 'Bendera Negara',
  placeOfBirth: 'Tempat Lahir',
  delivery: 'Pengiriman',
  registrationNumber: 'Nomor Registrasi',
  insuredItemDetail: 'Detil Barang yang diasuransikan',
  itemDetails: 'Detil Barang',
  typeOfGood: 'Jenis Barang',
  insuranceInfo: 'Informasi Asuransi',
  trip: 'Perjalanan',
  termsAndConditionForPasarPolisPartner: 'Syarat & Ketentuan Pendaftaran Mitra PT Pasarpolis Insurance Broker',
  pkgNotExist: 'Maaf, belum ada produk asuransi yang sesuai dengan data properti Anda',
  shipMaterials: 'Material Kapal',
  shipManufacturingYear: 'Tahun Produksi Kapal',
  shipClassification: 'Klasifikasi Kapal',
  shipType: 'Jenis Kapal',
  airplaneRegistrationName: 'Nama Pesawat',
  airPlaneRegistrationNo: 'Nomor Registrasi Pesawat',
  flightNo: 'Nomor Penerbangan ',
  select: 'Pilih',
  selectAll: 'Pilih Semua',
  selectCode: 'Pilih Kode',
  selectProvince: 'Pilih Propinsi',
  selectCity: 'Pilih Kota / Kabupaten',
  modeOfTransport: 'Moda Transportasi',
  vehicleType: 'Jenis Transportasi',
  totalSumInsured: 'Total Nilai Pertanggungan',
  policeNumber: 'Nomor Polisi',
  bankTransfer: 'Transfer Bank',
  bankVa: 'Virtual Account',
  sendToCustomer: 'Kirim ke Pelanggan',
  sendToAnotherAddress: 'Kirim ke Alamat Lain',
  sendToAgent: 'Kirim ke Mitra',
  sendToPasarpolis: 'Ambil di PasarPolis',
  postalCode: 'Kode Pos',
  province: 'Propinsi',
  protectionType: 'Jenis Proteksi',
  city: 'Kota',
  addressDetail: 'Detil Alamat',
  bonus: 'Bonus',
  totalBonus: 'Total Bonus',
  totalPolicy: 'Total Polis',
  totalDownline: 'Total Downline',
  packageAddons: 'Paket Tambahan',
  shippingOption: 'Opsi Pengiriman',
  thisOptionIsNotSelected: 'Opsi ini tidak dipilih',
  calculation: 'Perhitungan',
  mobilManualOrder: 'Mobil: Pesanan Manual',
  fleetManualOrder: 'Pesanan Armada/Manual',
  manual: 'Manual',
  timePeriod: 'Jangka Waktu',
  orderDetails: 'Detil Pesanan',
  insuranceNotAvailable: 'Asuransi Tidak Tersedia',
  continueToNextStepIfNoPackages: 'Lanjutkan ke tahapan berikutnya jika tidak ada paket tambahan.',
  continueToNextStepIfNoModification: 'Lanjutkan ke tahapan berikutnya jika tidak ada modifikasi.',
  noInsurancePackageWerFound: 'Tidak ditemukan paket asuransi berdasarkan kriteria yang Anda masukkan.',
  taxOnCommision: 'Pajak Penghasilan dari Bonus',
  useManualOrders: 'Pakai Pesanan Manual',
  remainingBonus: 'Sisa Bonus',
  contactUs: 'Hubungi Kami',
  howToClaimTheEasier: 'Cara Klaim Yang Semakin Mudah!',
  pleaseAddProtectionBelow: 'Silahkan tambah perlindungan untuk polismu dibawah ini',
  nowYouCanClaim: 'Sekarang lapor klaim bisa langsung melalui PasarPolis! Hubungi kami sekarang!',
  nowReportClaimByPasarApp: 'Sekarang lapor klaim bisa langsung melalui PasarPolis!',
  guardianAgentMessage: 'Fasilitas yang dapat digunakan terbatas dikarenakan Anda menggunakan akun mitra',
  totalBillAfterEndorsement: 'Total Tagihan Sebelum Endorsement',
  additionalDiscountAfterEndorsement: 'Terjadi penambahan diskon setelah endorsement',
  changeInDiscountAfterEndorsement: 'Terjadi perubahan diskon setelah endorsement',
  changeInCommissionsAndPointsAfterEndorsement: 'Terjadi perubahan komisi dan poin setelah endorsement',
  endorsementDocuments: 'Dokumen Endorsemen',
  downlineLangsung: 'Downline Langsung',
  document: 'Dokumen',
  seeDetails: 'Lihat Perincian',
  copyOfPreviousPolicy: 'Salinan Polis Sebelumnya',
  thisMonth: 'Bulan Ini',
  lastMonth: 'Bulan Lalu',
  threeMontAgo: '3 Bulan Lalu',
  sixMonthAgo: '6 Bulan Lalu',
  selectDate: 'Pilih Tanggal',
  verification: 'VERIFIKASI',
  pendingVerification: 'Menunggu Verifikasi OTP',
  isVerified: 'Diproses',
  rejected: 'Ditolak',
  from: 'Dari',
  to: 'Hingga',
  pleaseSelectDate: 'Silakan pilih tanggal',
  filter: 'Filter',
  nameUpto22: 'Nama maksimum 22 karakter',
  regionalCode: 'Kode Daerah',
  numberAlreadyRegistered: 'Nomor yang Anda masukkan sudah terdaftar, tidak dapat digunakan!',
  staffAccountCannotBeActivatedYouCanOnlyHaveTwoActiveAccounts: 'Akun staf tidak dapat diaktifkan. Anda hanya dapat memiliki 2 akun aktif.',
  youCanSearchTransactionBasedOn: 'Anda dapat mencari transaksi berdasarkan nama tertanggung, ID transaksi, nomor polis, maupun nama perusahaan asuransi',
  searchForTransactions: 'Cari transaksi',
  madeBy: 'Dibuat oleh',
  myStaff: 'Staff Saya',
  changeMobileNumber: 'Ubah Nomor Handphone',
  deactivateIt: 'Non-aktifkan',
  active: 'Aktif',
  notActiveState: 'Belum Aktif',
  activateIt: 'Aktifkan',
  notActive: 'NON-AKTIF',
  alreadyHaveTwoAccountsDeactOneTOAddNew: 'Anda telah memiliki 2 akun staff saat ini. Silakan menonaktifkan akun staff yang sudah ada apabila ingin menambahkan akun baru',
  youCanStillAddOneMoreStaff: 'Anda masih bisa menambahkan 1 staff lagi',
  staffFeatureInactive: 'Fitur Akun Staff Anda sedang tidak aktif',
  youCanHaveMaxTwoAccounts: 'Anda hanya dapat memiliki maksimum 2 akun staff aktif',
  name: 'Nama',
  sellPolicySmartWay: 'Jual Polis Cara Pintar',
  startNowAndBePartOfFuture: 'Mulai dari sekarang dan jadilah bagian dari asuransi masa depan',
  signupNow: 'Daftar Sekarang',
  whatDoPartnersGet: 'Apa yang Mitra Dapatkan?',
  benefitsNBenefits: 'Keuntungan dan manfaat yang mitra bisa dapatkan jika bergabung dengan kami',
  certainStepsToReachProfit: 'Langkah Pasti Meraih Keuntungan',
  whatAreThePartnersWhoJoined: 'Apa Kata Mitra yang Telah Bergabung?',
  mitraIsPartOfPasarpolis: 'PasarPolis Mitra adalah bagian dari PasarPolis Insurance Broker',
  privacyPolicy: 'Kebijakan Privasi',
  termsOfUse: 'Ketentuan Pemakaian',
  selectBankToUse: 'Pilih Bank yang Anda gunakan',
  seeTransactionDetails: 'Lihat Detil Transaksi',
  seeTransactions: 'Lihat transaksi',
  otherStatus: 'Status Pesanan',
  allStatus: 'Semua Status',
  instructions: 'Pilihan Instruksi',
  recipientName: 'Nama Penerima',
  accountName: 'Nomor Rekening',
  accountName1: 'Nama Akun',
  pasarpolisBankName: 'PT PasarPolis Insurance Broker',
  pasarpolisAccNo: '450-9050888',
  amountAndAccountDetail: 'Jumlah dan Detil Rekening',
  saveAsPdf: 'Simpan sebagai PDF',
  onlyOnePolicyCanBeSelected: 'Hanya 1 polis yang dapat dipilih dalam satu waktu',
  customerSupportPhone: '021 5089 0580',
  customerSupportWhatsapp: '0877 0017 8000',
  customerSupportMail: 'cs.mitra@pasarpolis.com',
  needHelpCustomerCare: 'Butuh bantuan? SIlakan hubungi customer service kami',
  differenceToBePaid: 'Anda masih mempunyai selisih yang harus dibayar',
  paymentProofDescription: 'Dari bukti pembayaran Anda yang kami terima, Anda masih perlu menambahkan pembayaran untuk pelunasan. Agar polis bisa segera diterbitkan, mohon konfirmasi metode pelunasan pembayaran apakah ingin dikurangi dari Komisi dan Saldo Dompet Anda.',
  warmRegards: 'Salam hangat',
  amountToBePaid: 'Jumlah yang harus dibayar',
  offline: 'Offline',
  usingPaymentProof: 'Menggunakan bukti pembayaran',
  useBonus: 'Pakai Bonus',
  bonusUsed: 'Bonus yang akan dipakai',
  afterTaxes: 'Sisa setelah potong pajak',
  useBalance: 'Pakai Saldo',
  commissionUsed: 'Komisi yang akan dipakai',
  pointsUsed: 'Poin yang akan dipakai',
  incomeTax: 'Pajak Penghasilan',
  takenFromBunus: 'Diambil dari bonus yang akan dipakai',
  finalDifference: 'Selisih Akhir',
  approveDifferencePayment: 'Setujui Pembayaran Selisih',
  dontShowPageAgain: 'Jangan tampilkan halaman ini lagi',
  vehicleData: 'Data Kendaraan',
  copyAmount: 'Salin Jumlah',
  copyVa: 'Salin Nomor Virtual Account',
  copyAmountSuccess: 'Jumlah Pembayaran berhasil disalin',
  copyVaSuccess: 'Nomor Virtual Account berhasil disalin',
  transferToVa: 'Silakan transfer ke nomor Virtual Account',
  accessoriesName: 'Nama Aksesoris',
  locationOfRisk: 'Lokasi Resiko',
  enterRiskLocation: 'Masukan Lokasi Resiko',
  accessoriesPrice: 'Harga Aksesoris',
  protectionName: 'Nama Perlindungan',
  protectionDes: 'Deskripsi Perlindungan',
  add: 'TAMBAH',
  addNew: 'Tambah',
  day: 'hari',
  info: 'INFO',
  level: 'Level',
  agree: 'Ya',
  id: 'ID',
  send: 'KIRIM',
  total: 'Total',
  ignore: 'ABAIKAN',
  pay: 'Bayar',
  required: 'Harus Diisi',
  mustPay: 'Harus dibayar',
  paidByNew: 'Dibayar oleh',
  customer: 'Pelanggan',
  agent: 'Agen',
  quoteAccepted: 'Penawaran Diterima',
  creditCard: 'Kartu Kredit',
  banking: 'Rekening bank',
  bankAccount: 'Rekening Bank',
  transactionId: 'ID Transaksi',
  shortTermsAndCondition: 'S & K',
  benefitType: 'Tipe Benefit',
  benefitNew: 'Benefit',
  mitraPoint: 'POIN MITRA',
  yes: 'Iya',
  yesSimpan: 'YA, SIMPAN',
  no: 'Tidak',
  standard: 'Standar',
  questionnaire: 'Kuisioner',
  optional: 'Opsional',
  reviewChanges: 'Tinjau Perubahan',
  nonStandard: 'Non Standar',
  accessoriesType: 'Tipe Aksesoris',
  transaction: 'Transaksi Detil',
  transactionType: 'Jenis Transaksi',
  youCanSearchOrderByNameAndId: 'Anda dapat mencari pesanan berdasarkan nama dan ID transaksi.',
  combinationOf17NumberAndLetters: 'Gabungan huruf dan angka berjumlah 17 (tujuh belas) karakter.',
  combinationOf16NumberAndLetters: 'Gabungan huruf dan angka berjumlah 16 (tujuh belas) karakter.',
  changeTransactionType: 'Anda dapat merubah jenis transkasi dari transaksi Normal menjadi Pay Later.',
  verificationData: 'Verifikasi Data',
  transportationType: 'Jenis Kendaraan',
  addProtection: 'Tambah Perlindungan',
  searchCity: 'kota Pencarian',
  selectAdditonalProtection: 'Pilih tambahan perlindungan',
  vehicleBrands: 'Merek Kendaraan',
  vehicleModel: 'Model Kendaraan',
  vehiclePrice: 'Harga Kendaraan',
  premiumPrice: 'Harga Premi',
  vehicleModalAndSeries: 'Model & Seri Kendara`an',
  modelAndSeries: 'Model & Seri',
  referralDate: 'Tanggal Referral',
  benefitUpTo: 'Manfaat hingga:',
  areaCode: 'Kode Wilayah',
  policyStartDate: 'Tanggal Mulai Polis',
  policyDate: 'Tanggal Polis',
  passengerCar: 'Mobil Penumpang',
  bus: 'Bis',
  truckPickup: 'Truk Pickup',
  policyEndDate: 'Tanggal Berakhir Polis',
  offerThisPackage: 'TAWARKAN PAKET INI',
  buyTheInsurancePkg: 'BELI PAKET ASURANSI INI',
  offerHealthInsurance: 'Tawarkan Asuransi Kesehatan',
  otherCost: 'Biaya Lainnya',
  others: 'Lainnya',
  enterPolicyStartDate: 'Masukan Tanggal Mulai Polis',
  productionYear: 'Tahun Pembuatan',
  paymentAmount: 'Jumlah Pembayaran',
  photoOfProofPayment: 'Foto Bukti Pembayaran',
  next: 'SELANJUTNYA',
  photoProof: 'Bukti Foto',
  hotline: 'Hotline',
  whatsApp: 'WhatsApp',
  seeFeatures: 'Lihat Fitur',
  difference: 'Selisih',
  differenceCalculation: 'Selisih Perhitungan',
  max: 'Maks.',
  previous: 'SEBELUMNYA',
  previousNew: 'Sebelumnya',
  deductiblesOwnRisk: 'Deductibles/Own Risks',
  newNext: 'BERIKUTNYA',
  continue: 'SELANJUTNYA',
  previousContinue: 'Lanjutkan',
  nextSmall: 'Selanjutnya',
  partnerList: 'Daftar Mitra',
  myPartners: 'Mitra Saya',
  deletedSelectedPhotos: 'Hapus foto terpilih?',
  motorCycleInsurance: 'Asuransi Motor',
  submitPolicyIssue: 'AJUKAN PENERBITAN POLIS',
  reportThroughPasarPolis: 'Lapor klaim melalui PasarPolis',
  reportCliam: 'Lapor klaim',
  hassleFreeClaimProcess: 'Proses klaim yang anti ribet!',
  allClaimProcessByPasarPolis: 'Semua proses klaim melalui PasarPolis Mitra agar terhindar dari keterlambatan waktu. *',
  easilyTrackStatusOfYourClaim: 'Mudah melacak status klaim Anda melalui kontak yang tersedia.',
  surveyConducted: 'Survei Dilakukan',
  successfulClaim: 'Klaim berhasil',
  selectOption: 'Pilih Opsi',
  maximumMotorProtection: 'Proteksi Motor Maksimal',
  commissionForPartner: 'Komisi untuk Mitra',
  selectVehicleYear: 'Pilih Tahun Pembuatan Kendaraan',
  processGettingEasier: 'Proses yang semakin Mudah',
  serviceHours: 'Waktu Pelayanan: Senin - Jumat, 9:00 - 18:00 WIB.',
  typeToSearchVehicleBrand: 'Ketik Untuk Mecari Merek Kendaraan',
  typeToSearchVehicleModel: 'Ketik Untuk Mecari Model Kendaraan',
  pasarpolisMitraContest: 'PasarPolis Mitra Kontes',
  mitraInfo: 'Mitra Info',
  instantPayoutBalance: 'Saldo payout instan anda:',
  login: 'MASUK',
  welcomeTo: 'Selamat Datang di',
  travelWithoutWorry: 'Bepergian Kemanapun Tanpa Rasa Khawatir',
  benefitAgainstBadge: 'lagi untuk dapatkan benefit',
  withCarInsurance: 'Dengan Asuransi Mobil',
  joinNow: 'BERGABUNG SEKARANG',
  againToLevel: 'lagi menuju level',
  okay: 'TUTUP',
  offersHasBeenSent: 'PENAWARAN TELAH TERKIRIM',
  cancel: 'BATAL',
  cancelSmall: 'Batal',
  canceled: 'Dibatalkan',
  newCancel: 'Batalkan',
  use: 'GUNAKAN',
  new: 'BARU',
  save: 'SIMPAN',
  write: 'Tulis',
  transactionsFrom: 'Transaksi dari XXX',
  tax: 'Pajak',
  ktpNumber: 'KTP Number',
  newPolicy: ' Polis Baru',
  renewPurchase: 'Perpanjang Polis Mitra',
  renewFromOther: 'Perpanjang Polis di luar Mitra',
  policyExtensionFrom: 'Perpanjangan Polis dari',
  extendedPolicyStatus: 'Status Polis Perpanjangan',
  systemMaintenance: 'Info Pemeliharaan Sistem',
  photoWasSuccessfullyDeleted: 'Foto berhasil dihapus.',
  renewalPolicyPaymentStatus: 'Status Pembayaran Polis Perpanjangan',
  photoKTP: 'Foto KTP',
  photoNPWP: 'Foto NPWP',
  photoKK: 'Foto KK',
  photo: 'Foto',
  photoBirthCertificate: 'Foto Akta Lahir',
  birthCertificateNumber: 'Nomor Akta Lahir',
  NIKinKK: 'NIK di KK',
  insured: 'Tertanggung',
  insuredName: 'Nama Tertanggung',
  printPolicy: 'Cetak Polis',
  congenitalDisability: 'Cacat Bawaan',
  receiveFromProvider: 'Terima dari Provider',
  policySubmitDate: 'Tanggal Kirim Polis',
  policyReceiver: 'Penerima Polis',
  typeOfDelivery: 'Jenis Pengiriman',
  remarksSubmitted: 'Keterangan',
  hardCopyDeliveryDate: 'Tanggal Pengiriman Cetak Polis',
  stnkPhotoGuide: 'Panduan Foto STNK',
  ktpPhotoGuide: 'Panduan Foto KTP',
  npwpPhotoGuide: 'Panduan Foto NPWP',
  certificatePolicyGuide: 'Panduan Foto Sertifikat Polis',
  policyCertificate: 'Sertifikat Polis',
  policyPhotoCertificate: 'Sertifikat Polis',
  vehiclePhotoGuide: 'Panduan Foto Kendaraan',
  confirmationAndPay: 'KONFIRMASI & BAYAR',
  chooseShippingPolicy: 'Pilih Pengiriman Polis',
  ePolicyByEmail: 'E-Polis (melalui e-mail)',
  printPolicyCheck: 'Polis Cetak',
  printPolicyAdminFee: '(Biaya: Rp XXXX)',
  insuredmember: 'Rincian Anggota Tertanggung',
  notification: 'Notifikasi',
  sendVerificationEmail: 'KIRIM LINK VERIFIKASI',
  withdrawal: 'TARIK SALDO',
  myBalance: 'Saldo Saya',
  myPoints: 'Poin Saya',
  upload: 'Unggah',
  registrationSuccessful: 'PENDAFTARAN BERHASIL',
  registrationCongrats: 'Pendaftaran telah berhasil, selamat bergabung dengan Mitra PasarPolis!',
  congratulationYouHaveReachedTop: 'Selamat, Anda telah mencapai level tertinggi!',
  congratulationsYouHavePlatinum: 'Selamat anda telah mencapai level Platinum',
  thirdPartyLegal: 'Tanggung Jawab Hukum Pihak Ketiga',
  verifyEmail: 'Verifikasi Email',
  uploadIdCardPic: 'Unggah Foto KTP',
  uploadNewKtpPhoto: 'Unggah Foto Baru KTP',
  uploadNewNpwpPhoto: 'Unggah Foto Baru NPWP(opsional)',
  enterNPWPNumber: 'Masukan Nomor NPWP Anda',
  enterKTPNumber: 'Masukan Nomor KTP Anda',
  uploadNPWPPic: 'Unggah Foto NPWP(opsional)',
  uploadNPWPPlaceholder: 'Unggah Foto NPWP',
  ktpError: 'KTP harus 16 atau 18 digit',
  npwpError: 'NPWP harus 15 digit',
  uploadStart: 'Pengunggahan dimulai',
  writeNameOfProtection: 'Tulis nama perlindungan',
  writeDescription: 'Tulis deskripsi',
  writeProtectionLimit: 'Tulis limit perlindungan',
  fileSizeLarge: 'Ukuran file terlalu besar',
  fileDownloadedSuccessfully: 'File Berhasil Diunduh',
  emailVerificationSuccessful: 'VERIFIKASI EMAIL BERHASIL',
  verifyEmailFirst: 'Verifikasi email Anda:',
  verifyEmailSecond: 'telah berhasil. Setiap proses asuransi akan dikirimkan ke email ini.',
  towordsYourProfile: 'MENUJU PROFIL ANDA',
  verificationLinkExpired: 'LINK VERIFIKASI KEDALUWARSA',
  goVerificationPage: 'MENUJU HALAMAN VERIFIKASI',
  linkNotUsedContent: 'Link sudah tidak dapat digunakan. Email Anda sudah terverifikasi sebelumnya.',
  steps: 'Langkah',
  enterSomething: 'Masukkan sesuatu..',
  limit: 'Limit',
  benefit: 'Manfaat',
  exception: 'Pengecualian',
  claim: 'Klaim',
  claimMethos: 'Cara Klaim',
  claimProcedure: 'Prosedur Klaim',
  claimDocument: 'Dokumen Klaim',
  mainDocuments: 'Dokumen Utama',
  additionalDocument: 'Dokumen Tambahan',
  cancellationDocuments: 'Pembatalan Polis',
  documentsUnavailable: 'Dokumen belum tersedia',
  claimAssistance: 'Bantuan Klaim',
  directDownlines: 'Direct Downline Anda',
  policiesSoldByAgent: 'Poin & Polis terjual oleh',
  noDownlines: 'Tidak ada Downline',
  nothing: 'Tidak ada',
  donotMissOutExtraEarning: 'Jutaan Rupiah menanti Anda!',
  donotloseincome: 'Jangan Hilangkan Penghasilan Ekstra',
  grabYourChanceToEarnMillions: 'Raih peluang Anda untuk menghasilkan Jutaan Rupiah hanya dengan mengundang teman-teman Anda untuk bergabung dengan PasarPolis Mitra. Bagikan kode referensi Anda dan mulailah memperluas Jaringan Mitra Anda! Semakin besar jaringan Anda tumbuh, semakin banyak penghasilan Anda',
  itsFreeAddonPartOfBenefit: 'Gratis dan sudah bagian dari benefit',
  itsNotFreeAddon: 'Bagian dari benefit dan dikenakan biaya tambahan',
  downloadTheClaimForm: 'UNDUH FORMULIR KLAIM',
  here: 'disini',
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  platinum: 'Platinum',
  discount: 'Diskon',
  mobil: 'Mobil',
  property: 'Properti',
  yourTotalGWP: 'Total GWP Anda',
  inMillions: 'Dalam jutaan rupiah',
  totalGwpBasedOnOjk: 'Total GWP berdasarkan rate OJK',
  totalBill: 'Total Tagihan',
  autoShops: 'Bengkel Rekanan',
  registerPartnerWorkshop: 'Daftar Bengkel Rekanan',
  termsConditions: 'Syarat & Ketentuan',
  productSummary: 'Ringkasan Produk',
  sendOffer: 'KIRIM PENAWARAN',
  buyPackage: 'BELI PAKET',
  buyThisPackage: 'BELI PAKET INI',
  buyNow: 'BELI SEKARANG',
  nominal: 'NOMINAL',
  nominalNew: 'Nominal',
  percentageNew: 'Persentase',
  percentage: 'PERSENTASE',
  sendNow: 'KIRIM SEKARANG',
  commission: 'Komisi',
  totalCommission: 'TOTAL KOMISI',
  point: 'POIN',
  congratulation: 'Selamat',
  pointsSmall: 'Poin',
  totalPolicies: 'Polis terjual',
  totalPoints: 'Total Poin',
  commissionAgent: 'Komisi Agent',
  myTotalCommission: 'Total Komisi saya',
  transactionAmount: 'Komisi Transaksi Ini',
  finalCommission: 'Komisi Akhir',
  myTotalPoint: 'Total POIN saya',
  convertPoint: 'TUKAR POIN',
  enterPoints: 'Masukan Poin*',
  enterAmount: 'Masukan Jumlah',
  explanation: 'Penjelasan',
  maxAmountYourCommission: 'Maks. sebesar komisi Anda',
  percentagePlaceholder: 'Maks. XXX dari Total Premi',
  enterPriceAccessories: 'Masukan Harga Aksesoris',
  proofOfPayment: 'Bukti Pembayaran',
  sendProofPayment: 'Kirim Bukti Pembayaran',
  valueOfCoverage: 'Nilai Pertanggungan',
  coverage: 'Pertanggungan',
  vehicleModification: 'Modifikasi Kendaraan',
  insurancePkgDetail: 'Detil Paket Asuransi',
  insuranceProvider: 'Penyedia Asuransi',
  categoryInsurance: 'Kategori Asuransi',
  selectPkg: 'PILIH PAKET',
  pkgDetails: 'Detil Paket',
  pageNotFound: 'Page Not Found',
  loginBtnText: 'Login Sekarang',
  vehiclePhoto: 'Foto Kendaraan',
  productName: 'Nama Produk',
  premiumCalculationNote: 'Catatan Perhitungan Premi',
  review: 'Tinjauan',
  accessoriesPhoto: 'Foto Aksesoris',
  additionalAccessories: 'Aksesoris Tambahan',
  thereAreTemplateFileFormate: 'Tersedia file template dengan format Excel untuk anda Isi.',
  insuredObject: 'Objek Tertanggung',
  photoInsuredObject: 'Foto Objek Tertanggung',
  editAddonsData: 'Ubah Tambahan Paket',
  enterAdditionalInfo: 'Masukkan Informasi Tambahan',
  additionalInfo: 'Informasi Tambahan',
  reset: 'Reset',
  customerData: 'Data Pelanggan',
  customerDocument: 'Dokumen Pelanggan',
  agentData: 'Data Agen',
  agentId: 'ID Agen',
  saveChanges: 'Simpan Perubahan',
  iAgreeWith: 'Saya setuju dengan',
  agreeWithTermsConditions: 'Saya setuju dengan <span style="font-weight: 800; text-decoration: underline;">Syarat & Ketentuan</span> berlaku',
  agreeWithTermsConditionsCargo: 'Saya telah membaca dan menyetujui <span style="color: #A08E52">Syarat dan Ketentuan</span> PasarPolis Indonesia.',
  supplementalLiability: 'Tambahan Pertanggungan',
  coverageOfExtremeConditions: 'Pertanggungan Keadaan Ekstrim',
  useThePersonalPhotos: 'Gunakan foto terbaru dan milik pribadi (bukan dari sumber lain)',
  frontLook: 'Tampak Depan',
  toTheRightSide: 'Samping Kanan',
  leftSide: 'Samping Kiri',
  backView: 'Tampak Belakang',
  dashboard: 'Dashboard',
  back: 'KEMBALI',
  apply: 'Terapkan',
  viewBased: 'Lihat Berdasarkan',
  insuranceCompany: 'Perusahaan Asuransi',
  advanceBalance: 'Saldo Awal',
  normalTransactions: 'Transaksi Normal',
  paylaterTransactions: 'Transaksi Pay Later',
  allTypeOfTransactions: 'Semua Jenis Transaksi',
  partnerBalance: 'SALDO MITRA',
  newInsurance: 'Beli Polis Asuransi Baru',
  extendPPInsurance: 'Perpanjang Polis dari Mitra PP',
  extendOutsideInsurance: 'Perpanjang Polis diluar Mitra PP',
  contentsData: 'Isi Data',
  attachmentOffer: 'Lampiran Penawaran Asuransi',
  exchangePointNoApplicable: 'TUKAR POIN TIDAK BERLAKU',
  paymentMethodNotApplicable: 'METODE PEMBAYARAN TIDAK BERLAKU',
  insuranceNumber: 'Nomor Asuransi',
  invoiceNumber: 'Nomor Invoice',
  invoiceDate: 'Tanggal Invoice',
  paymentBy: 'Pembayaran Oleh',
  paymentInstalmentBy: 'Pembayaran Cicilan XXXX Oleh',
  paymentData: 'Data Pembayaran',
  instalment: 'Cicilan',
  paymentWithVa: 'Pembayaran dapat dilakukan melalui Virtual Akun Bank di bawah ini:',
  paymentWithCreditCard: 'Pembayaran dapat dilakukan melalui Kartu Kredit dengan',
  paymentWithGopay: 'Pembayaran dapat dilakukan melalui GoPay dengan',
  paymentWithPointsAndCommision: 'Pembayaran dilakukan menggunakan Komisi dan Poin dari wallet Mitra',
  endorsementPremiumChange: 'Perubahan Premi Endorsement',
  paymentWithBank: 'Pembayaran dapat dilakukan melalui akun Transfer Bank di bawah ini:',
  bcaAccountNo: 'Nomor Virtual Akun XXX',
  bcaAccountNo1: 'Nomor Rekening BCA',
  fullName: 'Nama Lengkap',
  participantFullName: 'Nama Lengkap Peserta',
  enterFullName: 'Masukan Nama Lengkap',
  enterKTPNumberPlaceholder: 'Masukkan Nomor KTP',
  enterNPWPPlaceholder: 'Masukkan Nomor NPWP',
  enterNameAsInKtp: 'Nama Lengkap (sesuai KTP)',
  enterNumberAsInKtp: 'Nomor KTP',
  enterNumberAsInNPWP: 'Nomor NPWP',
  phoneNumber: 'Nomor Ponsel',
  customerPhone: 'Nomor Handphone',
  enterPhoneNumber: 'Masukan Nomor Telepon',
  emailAddress: 'Alamat Email',
  email: 'Email',
  sendEmailSuccessful: 'Kirim Email Berhasil',
  cityOfResendencyAsKTP: 'Kota Tempat Tinggal (Sesuai KTP)',
  enterEmailAddress: 'Masukan Alamat Email',
  customerPremium: 'Perhitungan Premi',
  enterCustomerName: 'Masukkan Nama Pelanggan',
  additionalPackage: 'Tambahan Paket',
  additionalToPreviousPackage: 'Tambahan Paket Sebelumnya',
  additionalPackageChanges: 'Perubahan Tambahan Paket',
  basicPremium: 'Premi Dasar',
  conversionRate: 'Besaran Nilai Tukar',
  changeInPremiumAfterEndorsement: 'Perubahan Premi Setelah Endorsement',
  totalEndorsementFee: 'Biaya Endorsement',
  policyType: 'Jenis Polis',
  fleet: 'Armada',
  manualOrder: 'Pesanan Manual',
  getInstantCalculations: 'Dapatkan perhitungan instan',
  getYourFleetPolicy: 'Dapatkan polis armada mobilmu disini',
  getCarPolicyHere: 'Dapatkan polis mobil disini',
  policyExtension: 'Perpanjangan Polis',
  additionalProtection: 'Perlindungan Tambahan',
  subTotal: 'Sub Total',
  fleetDiscount: 'Diskon Fleet',
  currency: 'Kurs',
  stampDuty: 'Biaya Materai',
  premium: 'Premi',
  vehiclePremiumAccessories: 'Premi Kendaraan & Aksesoris',
  adminFee: 'Biaya Admin',
  serviceFee: 'Biaya Layanan',
  note: 'Catatan',
  offer: 'TAWARKAN',
  profession: 'Pekerjaan',
  generalEmployees: 'Karyawan Swasta',
  bumnEmployees: 'Karyawan BUMN',
  entrepreneur: 'Wiraswasta',
  civilServent: 'Pegawai Negeri Sipil (ASN)',
  housewife: 'Ibu Rumah Tangga',
  student: 'Pelajar',
  retired: 'Pensiunan',
  sumAssuredOf: 'Uang Pertanggungan sebesar:',
  address: 'Alamat Tempat Tinggal Sekarang',
  policyDeliveryAddress: 'Alamat Pengiriman Polis',
  shippingAddress: 'Alamat Pengiriman',
  addressAsKTP: 'Alamat (Sesuai KTP)',
  administrativeCost: 'Biaya Administrasi',
  totalBasicPremium: 'Total Premi',
  newTotalBasicPremium: 'Total Premi Dasar',
  totalOtherExpenses: 'Total Biaya Lain',
  price: 'Harga',
  vehicleNumber: 'Nomor Kendaraan',
  totalAccidentProtection: 'Total Perlindungan Kecelakaan',
  discountedPriceFromPartner: 'Potongan Harga dari Mitra',
  buyInsuranceNow: 'BELI ASURANSI SEKARANG',
  buyInsurance: 'BELI ASURANSI',
  fillInTheVehicleData: 'Isi Data Kendaraan',
  paymentConfirmation: 'Konfirmasi Pembayaran',
  confirmation: 'Konfirmasi',
  order: 'Pesanan',
  confirmationOffer: 'KONFIRMASI PENAWARAN',
  remainingPaymentTime: 'Sisa Waktu Pembayaran',
  downloadOffer: 'UNDUH PENAWARAN',
  downloadFile: 'UNDUH FILE',
  chooseTheInsurancePackageYouWant: 'Pilih paket asuransi yang Anda inginkan',
  hour: 'JAM',
  minute: 'MENIT',
  second: 'DETIK',
  stnkBstkPhoto: 'Foto STNK/BSTK',
  stnkPhoto: 'Foto STNK',
  guide: 'Panduan',
  otherDocumentIfAny: 'Dokumen Lainnya Jika Ada',
  letterOfAppointmentFromCustomer: 'Surat Penunjukan dari Pelanggan',
  insurancePurchaseDetails: 'Detail Pembelian Asuransi',
  paymentStatus: 'Status Pembayaran',
  transactionDetail: 'Detail Transaksi',
  transactionDate: 'Tanggal Transaksi',
  policyDetail: 'Detail Polis',
  paidBy: 'Dibayarkan Oleh',
  policyStatus: 'Status Polis',
  monthlyPremium: 'Premi Per Bulan',
  yearlyPremium: 'Premi Per Tahun',
  months: 'Bulan',
  days: 'Hari',
  year: 'Tahun',
  person: 'Orang',
  policyNumber: 'Nomor Polis',
  inusrancePolicyNumber: 'Nomor Polis Asuransi',
  plateNumber: 'Nomor Plat',
  vehicleUse: 'Penggunaan',
  chassisNumber: 'Nomor Rangka',
  chooseInsurance: 'Pilih Asuransi',
  machineNumber: 'Nomor Mesin',
  selectOfYearOfManufacturing: 'Pilih Tahun Pembuatan Kendaraan',
  yearOfVehicleManufacturing: 'Tahun Pembuatan Kendaraan',
  searchForVehicleBrand: 'Cari Merek Kendaraan',
  serviceVehicleModelSeries: 'Cari Model & Seri Kendaraan',
  enterMotorOrderNo: 'Masukan Nomor Rangka (VIN)',
  transmission: 'Transmisi',
  color: 'Warna',
  model: 'Model',
  vehicleColor: 'Warna Kendaraan',
  selectBank: 'Pilih Bank',
  policyCertDownloadSuccessfully: 'Sertifikat polis berhasil diunduh',
  downloadSuccessfully: 'berhasil diunduh.',
  protectionPeriod: 'Periode Perlindungan',
  choosePayment: 'PILIH PEMBAYARAN',
  paymentMethod: 'Metode Pembayaran',
  choosePaymentMethod: 'Pilih Metode Pembayaran',
  enterGoPayData: 'Masukan Data GoPay',
  goPayPhoneNumber: 'Nomor Telepon*',
  enterCardDetails: 'Masukan Data Kartu',
  validityPeriod: 'Masa Berlaku',
  personalInformation: 'Informasi Pribadi',
  mo: 'Bln',
  yr: 'Thn',
  payment: 'Pembayaran',
  done: 'Selesai',
  shareViaWhatsApp: 'BAGIKAN VIA WHATSAPP',
  shareViaWhatsAppNew: 'Bagikan Via Whatsapp',
  healthInsurance: 'Asuransi Kesehatan',
  healthProtection: 'Proteksi Kesehatan',
  referralEarn: 'Refer & Earn',
  moreFriendsMoreEarn: 'Banyak Teman, Lebih Banyak Hadiah!',
  cvvNumber: 'Nomor CVV',
  paymentDate: 'Tanggal Pembayaran',
  paymentTime: 'Waktu Pembayaran',
  futureMitra: 'Futura/PP Mitra',
  verifyNow: 'VERIFIKASI SEKARANG',
  transactionDetails: 'Rincian Transaksi',
  totalAccessoriesPrice: 'Total Harga Aksesoris',
  totalAdditionalCoverage: 'Total Premium Tambahan Pertanggungan',
  totalAdditionalCoverageNew: 'Total Perlindungan Tambahan',
  mitraChoosePaymentMethod: 'Pilih Cara Pembayaran',
  paymentByCustomer: 'Pembayaran oleh Customer',
  paymentByAgent: 'Pembayaran oleh Mitra',
  sendOfferLink: 'KIRIM LINK PENAWARAN',
  bidding: 'Penawaran',
  delayed: 'Tertunda',
  specification: 'Spesifikasi',
  waitForPayment: 'Tunggu Pembayaran',
  sendPaymentLink: 'Kirim Link Pembayaran',
  useMyBalance: 'Gunakan Saldo Saya',
  useCommissionBalance: 'Gunakan Saldo',
  maxBalanceThatCanBeUsed: 'Maksimum saldo yang dapat digunakan: XXXX',
  useMyPoints: 'Gunakan Poin Saya*',
  useAgentPoints: 'Gunakan Poin',
  furtherPayment: 'LANJUT PEMBAYARAN',
  information: 'Informasi',
  yesIAgree: 'YA, SAYA SETUJU',
  yesOk: 'Ya, tutup',
  closeQuoteCreation: 'Tutup Pembuatan Qoutation',
  agreeNew: 'Setuju',
  disagree: 'Tidak Setuju',
  searchTheGarage: 'Cari bengkel',
  haveYouReadAndAgreedOffer: 'Apakah Anda telah membaca dan menyetujui seluruh isi penawaran?',
  pleaseReadTheTermsToAgree: 'Silahkan membaca Syarat & Ketentuan diatas untuk menyetujui',
  searchAgentByMobileAndNumber: 'Masukkan nama atau nomor ponsel',
  smsSuccessful: 'SMS Berhasil',
  paymentWasSuccessFul: 'Pembayaran Berhasil',
  transferSuccessful: 'TRANSFER BERHASIL',
  pointsTransaferMsg: 'Poin senilai Rp XXXX telah berhasil ditransfer ke saldo bonus Anda',
  myBonusBalance: 'Saldo Bonus Saya',
  paymentFailed: 'PEMBAYARAN GAGAL',
  loginAsAgentSuccessful: 'LOG IN MITRA BERHASIL',
  backToHome: 'KEMBALI KE BERANDA',
  backToHomeSentence: 'Kembali ke Beranda',
  changePolicyData: 'Ubah Data Polis',
  policyData: 'Data Polis',
  insuranceProviderNew: 'Provider Asuransi',
  home: 'Beranda',
  transaction1: 'Transaksi',
  balance: 'Dompet',
  eLearning: 'eLearning',
  profile: 'Akun',
  backToPayment: 'KEMBALI KE PEMBAYARAN',
  useOfBalance: 'Penggunaan Saldo',
  fromYourCommission: 'Penggunaan Komisi',
  transactionCommission: 'Komisi Transaksi',
  useOfPoint: 'Penggunaan Poin',
  copy: 'Salin',
  share: 'Bagikan',
  myAccount: 'Akun Saya',
  myProfile: 'Profil Saya',
  selectHelp: 'Pilih Bantuan',
  help: 'Bantuan',
  exit: 'KELUAR',
  agentCommission: 'komisi Mitra',
  referNow: 'Ajak Sekarang',
  referNEarn: 'Penghasilan Tambahan Referral',
  referNEarnV2: 'Gabung PasarPolis Mitra App',
  downline: 'Downline',
  policyDownline: 'Polis Downline',
  premiumDownline: 'Premi Downline',
  myDownline: 'Downline Saya',
  myDownlineNew: 'Tim Saya',
  contactRm: 'Hubungi PIC Marketing',
  downlines: 'Downline',
  pointEarned: 'Points Earned',
  policies: 'Polis',
  faqs: 'Pertanyaan Sering Diajukan',
  faqs1: 'Pertanyaan yang Sering Ditanyakan',
  loginAsAgent: 'Masuk Sebagai Mitra',
  homePageGuide: 'Panduan Halaman Beranda',
  guidForOfferingInsurance: 'Panduan Menawarkan Asuransi Kesehatan',
  salesGuide: 'Panduan Penjualan Asuransi Kesehatan',
  jiwaFaqs: 'FAQ Asuransi Jiwa',
  salesCovidGuide: 'Panduan Penjualan Asuransi Kesehatan',
  rateUs: 'Berikan Penilaian',
  consist17Characters: 'Terdiri dari 17 karakter',
  minimum9Characters: 'Minimal 9 karakter.',
  makePayment: 'LAKUKAN PEMBAYARAN',
  downloadDetailTransactions: 'UNDUH RINCIAN TRANSAKSI',
  downloadDetailTransactionsNew: 'Unduh Rincian Transaksi',
  downLoadPolicyDocuments: 'Unduh Dokumen Polis',
  lookInvoice: 'Lihat Invoice',
  policyDocuments: 'Dokumen Polis',
  detailObject: 'Detil Objek',
  yourReferralCode: 'Kode Referral Anda',
  myReferralCode: 'Kode Referral Saya',
  sendEmailAgain: 'KIRIM LAGI',
  sendViaEmailAgain: 'KIRIM VIA EMAIL LAGI',
  contactAConsultant: 'Hubungi Konsultan',
  contactVia: 'Hubungi via:',
  contactPasarpolisTeam: 'Hubungi tim klaim PasarPolis via:',
  oops: 'Oops!',
  kode: 'Kode',
  platNumber: 'Plat Kendaraan',
  platNumberPlaceholder: '1234ABC',
  congratsOnSuccessfulSubmission: 'Selamat Anda telah berhasil mengirimkan jawaban',
  yourCurrentConsultant: 'Konsultan Anda Saat Ini:',
  paymentLinkSent: 'LINK PEMBAYARAN TELAH TERKIRIM',
  packageAccordingtoYourNeed: 'Paket Asuransi sesuai dengan kebutuhan Anda',
  insurancePackageWithSeveralofYourChoices: 'Paket Asuransi dengan beberapa pilihan Anda',
  otherPackages: 'Paket lainnya',
  otherProtection: 'Perlindungan Lainnya',
  protectPlus: 'Proteksi Plus',
  protectJiwa: 'Proteksi Jiwa',
  pleaseMakeAllInformationCorrect: 'Harap pastikan semua informasinya tepat dan benar',
  confirmationData: 'Konfirmasi Data',
  insuranceDetails: 'Rincian Asuransi',
  category: 'Kategori',
  period: 'Periode',
  brand: 'Merek',
  transactionsList: 'Daftar Transaksi',
  // start Covid Sharing
  covidTitle: 'Covid-19',
  selectedProviders: 'Provider Terpilih',
  tropicalDiseaseInsurance: 'Asuransi Penyakit Tropis',
  insuranceAgainstTropicalDiseases: 'Asuransi dengan perlindungan terhadap Penyakit Tropis',
  rederHealthInsurance: 'Asuransi dengan perlindungan terhadap Covid19',
  jiwaTitle: 'Proteksi Jiwa',
  offerSoulInsurance: 'TAWARKAN ASURANSI JIWA',
  offerInsurance: 'TAWARKAN ASURANSI',
  sendLinkToBuyCovid: 'Bagikan link untuk pembelian',
  insuranceProtectionAgainstCovid19: 'Asuransi dengan perlindungan terhadap',
  shareTheLinkForPurchasing: 'Bagikan link untuk pembelian',
  theCovidPolicy: 'Asuransi Kesehatan',
  Covid19: ' Covid19',
  jiwaPolicy: 'Asuransi Jiwa',
  fromNowOn: 'MULAI SEKARANG',
  noTransactions: 'Tidak Ada Transaksi',
  rederCovidInsurance: 'REKOMENDASIKAN ASURANSI COVID',
  offerTropicalIssuesInsurance: 'REKOMENDASIKAN ASURANSI PENYAKIT TROPIS',
  recommendHealthInsurance: 'REKOMENDASIKAN ASURANSI KESEHATAN',
  shareonWhatsApp: 'BAGIKAN DI WHATSAPP',
  shareOnWhatsAppNew: 'Bagiakn via WhatsApp',
  sendViaWhatsApp: 'KIRIM VIA WHATSAPP',
  sendShareLink: 'KIRIM VIA SMS',
  enterMobileNumber: 'Masukan Nomor Ponsel',
  covidMobilePlaceholder: 'Contoh : 0890 1122 3040',
  sendSmsWithReferralLink: 'Link referral untuk pembelian polis akan segera dikirimkan ke nomor ponsel ini',
  copyLink: 'SALIN LINK',
  copyLinkNew: 'Salin Link',
  submitCalculation: 'Kirim Perhitungan',
  payInstalmentTo: 'Bayar Cicilan Ke',
  enterCovidPolicyMobileNumber: 'Masukkan nomor ponsel dari pelanggan yang ingin Anda tawarkan',
  reviewYourDetailsBeforeSubmit: 'Pastikan semua informasi yang Anda masukkan sudah benar.',
  selectMember: 'Pilih Tertanggung',
  policyHolderNew: 'Pemegang Polis',
  policyHolder: 'Data Pemegang Polis',
  personalDetails: 'Data Pribadi',
  selectedPackages: 'Paket Dipilih',
  tropicalDisease: 'Penyakit Tropis',
  declarationForHealth: 'Deklarasi Kesehatan',
  pleaseEnterCustomerAddressFromTheAbove: 'Silakan masukkan alamat pelanggan dari atas',
  pleaseEnterUserDetails: 'Silakan masukan data pribadi Anda',
  dateOfBirth: 'Tanggal Lahir',
  relationshipWithPolicyHoder: 'Relationship with Policy Holder',
  relationshipWithInsured: 'Hubungan Dengan Tertanggung',
  beneficiaryDetails: 'Ahli Waris',
  gender: 'Jenis Kelamin',
  coveragePeriod: 'Periode Perlindungan',
  yourSelf: 'Your Self',
  self: 'Diri Sendiri',
  female: 'Perempuan',
  male: 'Laki-laki',
  child: 'Anak kandung',
  childLabel: 'Anak',
  brother: 'Kakak kandung',
  sister: 'Adik kandung',
  father: 'Ayah kandung',
  mather: 'Ibu kandung',
  paidTo: 'Dibayar ke',
  husbandWife: 'Suami/Istri',
  remove: 'HAPUS',
  change: 'UBAH',
  smallChange: 'Ubah',
  deleteLead: 'HAPUS LEAD',
  OR: 'ATAU',
  sellProtection: 'Jual Proteksi',
  protections: 'Proteksi',
  skip: 'MELEWATKAN',
  productInformationSummary: 'Ringkasan Informasi Produk',
  addtheMembersYouSecure: 'Tambahkan anggota keluarga untuk diasuransikan',
  pleaseEnterPolicyHolderData: 'Silakan masukan data Pemegang Polis',
  // END Covid
  all: 'Semua',
  alreadyPaid: 'Sudah Dibayar',
  waitingForPayment: 'Menunggu Pembayaran',
  // START instalments
  instalmentsPending: 'Cicilan sedang berjalan',
  payInInstalments: 'Bayar dengan Cicilan',
  totalInstalments: 'Jumlah Cicilan',
  instalmentDueDate: 'Tanggal Jatuh Tempo',
  instalmentSequenceNumber: 'Cicilan ke',
  instalmentsDetail: 'Rincian Bayar dengan Cicilan',
  instalmentPaymentDate: 'Tanggal Bayar',
  instalmentPaymentStatus: 'Status Bayar',
  paymentProofUploaded: 'Bukti Bayar Diserahkan',
  payInstalment: 'Bayar Cicilan',
  payableAmount: 'Total Cicilan',
  // END instalments
  paymentFaild: 'Pembayaran Gagal',
  expired: 'Kedaluwarsa',
  inProcess: 'Dalam Proses',
  telephone: 'Telepon',
  paylater: 'PAYLATER',
  bonusReferral: 'Bonus Referral',
  payLaterOptionSelected: 'Pembayaran PayLater Telah Dipilih',
  howItWorks: 'Bagaimana itu bekerja:',
  policyWillExpire: 'Polis akan berakhir XXX',
  makeSureAllInfoAreCorrect: 'Pastikan semua informasi yang Anda masukkan sudah benar.',
  forNowPaymentOnlyAvailableForCustomer: 'Untuk saat ini, pembayaran Asuransi Kesehatan hanya tersedia pilihan Pembayaran Oleh Customer. Mohon maaf atas ketidak-nyamanannya.',
  yourRemainingPayLaterQuota: 'Kuota anda tersisa XXX untuk bulan ini.',
  inviteFriendsToGetPasarPolisPartnersBenefit: 'Undang teman untuk bergabung dengan PasarPolis Mitra dan dapatkan manfaat tambahan dari kami.',
  inviteFriendToSellAsManyPolices: 'Ajak teman untuk menjual polis sebanyak-banyaknya dan teman akan mendapatkan bonus:',
  yourQuotaUsedForTheMonth: 'Kuota anda telah habis untuk bulan ini. Anda dapat menggunakan kembali fitur ini di awal bulan depan.',
  congratsSuccessfullyLoggedInAsPartner: 'Selamat Anda berhasil terdaftar sebagai mitra PasarPolis',
  congretsSuccessfullyLogedInAs: 'Anda masuk sebagai Mitra dengan akun',
  youHaveReachedTheTop: 'Anda salah satu mitra terbaik!',
  doYouWantToDownloadDetails: 'Apakah Anda ingin mengunduh rincian data transaksi ini?',
  referMoreAgentsDirectlyUnderYou: 'Ajak lebih banyak mitra dan perluas jaringan Anda untuk penghasilan lebih banyak.',
  grabYourChanceEarnMillions: 'Mau penghasilan jutaan Rupiah? Ajak teman-teman Anda untuk bergabung bersama PasarPolis Mitra hanya dengan membagikan kode referral Anda! Semakin besar jaringan, semakin besar pendapatan Anda!',
  getPointsOnTransactions: 'Dapatkan <b>XXXX Poin</b> dengan melakukan transaksi ini',
  payLaterStatementforAgent: 'Polis akan langsung Anda terima, namun polis akan dibatalkan jika pembayaran tidak dilakukan dalam waktu XXXX hari.',
  recommendInsuranceNow: 'Ajak Teman Dapat Bonus!',
  referYourFriendsEarn: 'Ajak teman Anda dan kami akan memberikan bonus referral sebesar Rp 10.000 kepada teman Anda.',
  npwpRequiredForCorporateNeeds: 'Foto NPWP (diperlukan untuk kebutuhan korporat)',
  latestOnPasarPolisApp: 'Terbaru di PasarPolis App',
  salesGuidPasarPolis: 'Panduan Penjualan PasarPolis Mitra',
  justShowPartnerWorkShop: 'Tampilkan bengkel rekanan saja',
  noDataAtThisTime: 'Saat ini belum ada data yang ditemukan',
  paymentLinkSentEmail: 'Link pembayaran telah terkirim ke alamat email:',
  paymentActiveTimeStatement: 'dan hanya berlaku untuk 1x24 jam.',
  paymentBeingProcessed: 'PEMBAYARAN SEDANG DIPROSES',
  minimumPaymentOfIDR: '*Minimum pembayaran Rp 10.000',
  changeEmailAddress: 'Ubah Alamat Email',
  policyStartFromDay: '*Terhitung dari tanggal pembayaran polis',
  lippoPolicyStartingDay: '*Dimulai dari 7 hari setelah tanggal pembayaran polis',
  leadRemoveSuccessful: 'Timbal berhasil dihapus',
  previousEmailAddress: 'Alamat Email Sebelumnya',
  newEmailAddress: 'Alamat Email Baru',
  youCanEnterAsPartnerStatement: 'Anda dapat masuk sebagai Mitra yang telah Anda rekrut. Seluruh aktifitas dan komisi akan tetap ditujukan kepada Akun Mitra yang Anda masuki.',
  leadNameUpdateSuccessful: 'Berhasil memperbarui nama',
  deliveryConfirmation: 'Konfirmasi Pengiriman',
  resendToEmail: 'Kirim Ulang Email',
  partnerLogoutSuccessfully: 'LOG OUT MITRA BERHASIL',
  paymentOfPaymentSuccessful: 'Pengiriman Bukti Pembayaran Berhasil',
  thankYouForYourTrust: 'Terima kasih atas kepercayaan Anda terhadap layanan kami.',
  logoutAsPartner: 'KELUAR SEBAGAI MITRA',
  logout: 'keluar',
  linkSendSuccessfully: 'Tautan berhasil dikirim!',
  uploadNewPhoto: 'Unggah Foto Baru',
  viewYourOrder: 'Lihat Pesanan Anda',
  viewOffer: 'Lihat Penawaran',
  acceptOffer: 'Terima Penawaran',
  ordersAreMade: 'Pesanan dibuat',
  orderSent: 'Pesanan Terkirim',
  changeEmailSuccessful: 'Ubah alamat email berhasil.',
  selectRepeatTransaction: 'Pilih Ulang Transaksi',
  overPaymentDifferenceAddToWallet: 'Selisih lebih bayar akan dikreditkan ke Dompet Mitra Anda.',
  thankYouToTrustInOurService: 'Terima kasih atas kepercayaan Anda terhadap layanan kami. Kami akan menginfokan penawaran terbaik kepada Anda melalui email dan sms. Anda dapat meninjau and menyetujui penawarannya melalui aplikasi PasarPolis Mitra.',
  congratsYouLogoutFromPartner: 'Selamat! Anda berhasil keluar dari akun mitra',
  pleaseVerifyKtpNPWP: 'Harap verifikasi KTP dan NPWP(opsional) Anda',
  increaseYourDownlineToEarnMore: 'Penghasilan jutaan menanti Anda hanya dengan mengajak downline untuk menambah anggota mereka! Ajak sekarang.',
  makeSureCarPicClear: 'Pastikan Foto Mobil yang Anda unggah memiliki kualitas yang jelas (tidak buram)',
  motorCycleChassisNumberFound: 'Nomor Rangka sepeda motor atau VIN Anda dapat ditemukan di bagian kiri bawah STNK Anda.',
  makeSureVehicleRegistrationPicClear: 'Pastikan Foto STNK yang Anda unggah memiliki kualitas yang jelas (tidak buram)',
  makeSureKTPPhotoClear: 'Pastikan Foto KTP yang Anda unggah memiliki kualitas yang jelas (tidak buram)',
  makeSureNPWPPhotoClear: 'Pastikan Foto NPWP yang Anda unggah memiliki kualitas yang jelas (tidak buram)',
  verifyThePolicyCertificate: 'Pastikan Foto Sertifikat Polis yang Anda unggah memiliki kualitas yang jelas (tidak buram)',
  page404Description: 'The page you are looking for might has been removed, renamed or does not exist',
  walletEmptyStatement: 'Belum ada transaksi berdasarkan periode yang dipilih',
  selectThisPolicyIfWantHardCopy: 'Pilih opsi ini jika Anda ingin menerima polis secara fisik.',
  selectThisIfCongenitalDamage: 'Pilih opsi ini jika kendaraan Anda memiliki cacat bawaan.',
  additionalFeesOnSelectHardCopy: '(berlaku biaya tambahan Rp XXXX)',
  paymentDeadLine: 'Batas waktu pembayaran tanggal:',
  paymentDeadLine2: 'Batas waktu pembayaran',
  returnToTransactionList: 'Kembali ke Daftar Transaksi',
  calculateTheTotalBill: 'Hitung Total Tagihan',
  maxDiscountLimit: 'Batas maksimum diskon 25%.',
  enterTheAccountMenu: 'Masuk ke menu Akun',
  nowYouCanContactPICMarketing: 'Sekarang Anda dapat menghubungi PIC Marketing melalui menu Akun untuk berkonsultasi atau menanyakan perihal polis & fitur di aplikasi Mitra.',
  youHaveOverPaymentDifference: 'Anda mempunyai selisih lebih bayar. Selisih tersebut akan dikreditkan ke Dompet Mitra Anda.',
  youDonotHaveStaff: 'Anda belum memiliki staff',
  addStaff: 'Tambah Staff',
  initiated: 'DIMULAI',
  maximumStaffThatCanAdded: 'Maksimum staff yang bisa anda tambahkan: 2 orang.',
  addStaffToHelp: 'Tambahkan staff (icon +) untuk membantu aktifitas Anda di PasarPolis Mitra. Anda juga dapat melihat performa staff Anda.',
  writeTheDateAndSelectTheTimePeriod: 'Tulis tanggal dan pilih jangka waktu untuk menampilkan tanggal berakhir polis.',

  doYouWantToUndoTheChanges: 'Apakah Anda ingin membatalkan perubahan yang sudah Anda buat?',
  areYouSureYourChangesAreCorrect: 'Apakah Anda yakin perubahan yang Anda buat sudah benar?',
  selectCityInWhichYouOfferInsurance: 'Kota di mana Anda menawarkan asuransi',
  transactionTypeHasBeenChange: 'Jenis transaksi telah diubah menjadi Pay Later.',
  differenceIsTaxFree: 'Selisih ini bebas pajak.',
  areYouSureYouWantToRemovePolicy: 'Apakah Anda yakin ingin menghapus kebijakan',
  transactionEditSuccessful: ' Detil transaksi berhasil diubah',
  maxPointsThaCanBeUse: 'Maksimum saldo yang dapat digunakan: XXXX. Konversi 1 poin = Rp 1.000',
  areYouSureYouWantToChangeTransaction: 'Apakah anda yakin untuk merubah jenis transaksi menjadi Pay Later?',
  youCanChangeTheTypeOfTransaction: 'Anda dapat merubah jenis transkasi dari transaksi Normal menjadi Pay Later.',
  youCanNotPayThisAgent: 'Anda tidak dapat menyelesaikan pembayaran untuk transaksi ini. Silakan menghubungi customer untuk menyelesaikan pembayaran.',
  youCanNotPayThisCustomer: 'Anda tidak dapat menyelesaikan pembayaran untuk transaksi ini. Silakan menghubungi agent untuk menyelesaikan pembayaran.',
  withdrawalAmountNotify: 'Penting: Anda dapat mencairkan saldo hingga Rp 100.000 per polis terjual (berlaku kelipatan)',
  yourDocumentUnderVerification: 'Dokumen Anda saat ini masih dalam proses verifikasi. Anda akan mendapatkan pemberitahuan melalui email setelah proses verifikasi selesai dilakukan',
  initialBalanceIsGivenAndRestAdjusted: 'Saldo awal diberikan, saldo ini otomatis disesuaikan kapan saja Anda menerima komisi hingga jumlah saldo awal terbayar',
  pasarPolisCannotWithDrawalPartnerAmount: 'Tim PasarPolis tidak dapat melakukan penarikan saldo Mitra',
  youDonotHavePartners: 'Saat ini Anda belum memiliki Mitra yang telah direkrut',
  pasarPolisCannotRedeemPartnerPoints: 'Tim PasarPolis tidak dapat melakukan penukaran poin Mitra',
  teamCannotmakePayemnts: 'Tim PasarPolis tidak bisa melakukan pembayaran atas nama Mitra',
  withDrawalBalanceNotApplicable: 'TARIK SALDO TIDAK BERLAKU',
  youCanCalculateTheUnpaidTransactions: 'Anda dapat menghitung total tagihan untuk transaksi yang belum dibayarkan sekaligus mengirimkan bukti pembayarannya.',
  bonusForFriends: 'Bonus untuk Teman',
  onlyAppliesForMorePremium: '*Hanya berlaku untuk premi lebih dari Rp 3.000.000',
  onlyValidForMvAndCarInsurance: '*Hanya berlaku untuk polis asuransi Mobil & Properti dengan nilai > Rp 3.000.000, kedua polis pertama & ketiga harus terjual dalam waktu 30 hari sejak verifikasi KTP',
  referNEarnTnC1: '*Hanya berlaku untuk polis dengan nilai > Rp3.000.000. Polis harus dijual dalam waktu 30 hari sejak tanggal verifikasi ID.',
  referNEarnTnC2: '**Hanya berlaku untuk polis dengan nilai > Rp3.000.000. Polis ketiga harus dijual dalam waktu 30 hari setelah penjualan polis pertama.',
  pleaseSelectThePartnerToWhichYouWant: 'Silahkan pilih Mitra dibawah yang Anda ingin masuk menjadi Mitra tersebut.',
  agreeWithHealthDeclaration: 'Saya menyatakan bahwa saya dalam kondisi yang sesuai dan setuju dengan',
  agreeWithTheTermsAndConditionPolicy: 'Saya setuju dengan XXXXXX yang berlaku dan telah membaca PXXXXX secara saksama',
  maximumBonusForMotorVehicle: 'Maksimum Bonus untuk kendaraan bermotor adalah 25%',
  maximumBonusForMarineCargo: 'Maksimum bonus untuk kargo adalah 15%',
  paymentProcessed: 'Pembayaran Diproses',
  kargo: 'Kargo',
  maximumBonusForHealthProtection: 'Maksimum Bonus untuk asuransi kesehatan adalah 20%',
  userConfirmationStatement: 'Saya sudah membaca dan memahami Ringkasan Informasi produk dan setuju dengan Syarat dan Ketentuan berlaku',
  inviteYourFriends: 'Ajak teman Anda dan kami akan memberikan bonus referral sebesar Rp 10.000 kepada teman Anda', // 'Ajak teman Anda dan kami akan memberikan bonus referral sebesar Rp 10.000 untuk Anda dan teman Anda.',
  retailAgentFriendInvite: 'BONUS BERLIMPAH CUKUP AJAK TEMAN DAN RAJIN JUALAN!',
  sorryNoVehiclePkgStatement: 'Maaf, belum ada produk asuransi yang sesuai dengan data kendaraan Anda',
  policySentAfterApproval: 'Polis Anda segera dikirimkan setelah mendapat persetujuan dari pihak asuransi. Batas waktu pembayaran tanggal:',
  policyNotificationInProcessOfApproval: 'Pemberitahuan Polis dalam proses persetujuan oleh pihak asuransi dan link pembayaran telah dikirimkan ke:',
  agentCommissionNotAddedStatement: '*Komisi sudah digunakan sebagai pembayaran dan tidak ditambahkan ke saldo komisi Anda',
  failedPaymentStatement: 'Pembayaran Anda gagal. Pastikan semua data yang Anda masukan sudah benar atau gunakan metode pembayaran lainnya.',
  shareBtnText: 'Dapatkan penghasilan jutaan Rupiah tanpa investasi apapun. Akses produk-produk asuransi hanya dalam satu platform. Bekerjalah dari tempat dan waktu sesuka Anda. Daftar di aplikasi PasarPolis Mitra sekarang.',
  shareBtnTextV2: 'Gabung PasarPolis Mitra App dengan input kode referal',
  shareBtnTextV3: '. Download and daftar sekarang di:',
  earnReferralStatement: 'Raih peluang untuk mendapatkan jutaan Rupiah hanya dengan mengundang teman-teman Anda untuk bergabung bersama PasarPolis Mitra. Bagikan kode referral Anda dan mulailah memperluas jaringan Mitra Anda! Semakin besar jaringan, semakin besar pendapatan Anda.',
  paymentPendingStatement: 'Status pembayaran Anda saat ini sedang diproses. Kami akan menginformasikan kepada Anda melalui SMS dan Email dalam waktu maksimal 1x24 jam.',
  makeSureGoPayStatement: '*Pastikan Anda memasukan nomor telepon yang terdaftar pada akun GoPay Anda',
  verificationDocWarning: 'Verifikasi data Anda sekarang untuk dapat menggunakan komisi & poin sebagai pembayaran',
  thankForPolicy: 'Terima kasih atas pembelian Anda. Rincian polis dan detil transaksi akan dikirimkan ke email',
  smsLinkSendSuccessFulStatement: 'Link terkirim. Pastikan pelanggan telah memilih produk yang diinginkan dan melakukan pembayaran. Komisi akan langsung dicairkan ke dalam akun Anda setelah pelanggan menyelesaikan pembayaran. Untuk informasi lebih lanjut, silakan hubungi layanan Customer Service PasarPolis.',
  ktpEmailWarning: 'Sebagai Mitra, Anda wajib melampirkan KTP dan NPWP(opsional) serta melakukan verifikasi email untuk kelancaran proses.',
  covidLinkCopyMsg: 'Link telah disalin. Bagikan link ke pelanggan serta pastikan pelanggan telah memilih produk yang diinginkan dan melakukan pembayaran. Komisi akan langsung dicairkan ke dalam akun Anda setelah pelanggan menyelesaikan pembayaran. Untuk informasi lebih lanjut, silakan hubungi layanan Customer Service PasarPolis.',
  onChoosingCommissionStatement: 'Dengan memilih metode pembayaran ini, Anda sadar dan menyetujui bahwa tidak ada bonus yang diberikan dari transaksi ini, kecuali terjadi lebih bayar.',
  onChoosePaylaterOptionConfirmation: 'Dengan memilih opsi Pay Later, Anda sadar dan menyetujui bahwa Anda sebagai Mitra tidak dapat menggunakan saldo dalam pembayaran transaksi polis',
  policyProcessWithSelectedBranch: 'Penerbitan polis akan diproses melalui kantor pusat/cabang yang dipilih',
  agentDoingPaymentConfirmation: 'Dengan memilih opsi ini, Anda sadar dan menyetujui bahwa Anda sebagai Mitra yang dapat menyelesaikan transaksi ini dan tidak ada bonus yang diberikan dari transaksi ini, kecuali terjadi lebih bayar. Pelanggan tidak dapat menyelesaikan transaksi ini.',
  paymentPeriodStatement: 'Anda akan menerima polis setelah disetujui oleh Pihak Asuransi. Polis akan dibatalkan jika Anda tidak melakukan pembayaran dalam waktu XXX hari setelah polis disetujui.',
  underPaymentNotificationText: 'Jika jumlah pembayaran sudah sesuai dengan bukti pembayaran namun terdapat selisih kurang bayar, silakan transfer selisihnya ke akun yang sama dan unggah bukti pembayaran yang baru, atau pilih ulang transaksi yang anda pilih agar selisih menjadi sama atau lebih bayar.',
  haveGoodStudy: 'Selamat belajar!',
  courseStartContinue: 'Mulai Kursus',
  inviteYourFriendToGetAdditionalIncome: 'Ajak temanmu mendapatkan penghasilan tambahan semudah memasarkan polis lewat smartphone! Ajak teman kamu mendaftar di PasarPolis Mitra menggunakan kode referral-mu saat melakukan registrasi dan daptkan penghasilan tambahan bersama!',
  underpaymentDifference: 'Jika jumlah pembayaran sudah sesuai dengan bukti pembayaran namun terdapat selisih kurang bayar, silakan transfer selisihnya ke akun yang sama dan unggah bukti pembayaran yang baru, atau pilih ulang transaksi yang anda pilih agar selisih menjadi sama atau lebih bayar.Selisih lebih bayar akan dikreditkan ke Saldo Anda.',
  overpaymentDiffWillBeCreditedToAccount: 'Selisih lebih bayar akan dikreditkan ke Saldo Anda',
  remainingBonusWillBeCreditedToAccount: 'Sisa bonus akan dikreditkan ke Saldo Anda.',
  maxDiscountIsGreaterInfo: 'Diskon yang Anda berikan tidak dapat diproses karena melebihi batas maksimum. Silakan ubah diskon.',
  maxDiscountChangeInfo: 'Anda dapat memberikan diskon maksimum sampai XXXX',
  otherDocuments: 'Dokumen Lainnya',
  uploadGuide: 'Panduan Unggah',
  letterOfAppointment: 'Surat Penunjukan',
  fileType: 'File yang didukung: PDF, JPG/JPEG, PNG',
  uploadAppointmentLetter: 'Unggah surat penunjukan jika menggunakan provider yang sama',
  documentMustSignedByInsured: 'Dokumen harus ditandatangani oleh tertanggung.',
  understand: 'Mengerti',
  someAdditionalInfoRequired: 'Beberapa informasi tambahan dibutuhkan oleh penyedia asuransi.',
  needCalculationsWithSpecialConditions: 'Butuh perhitungan dengan kondisi khusus? Anda dapat menggunakan',
  someAdditionalInfoRequiredByProvider: 'Beberapa informasi tambahan dibutuhkan oleh penyedia asuransi. Bagian yang diberi tanda bintang (*) wajib diisi.',
  completeTheFormToGetInsuranceOption: 'Lengkapi formulir di bawah ini untuk mendapatkan pilihan asuransi. Bagian yang diberi tanda bintang (*) wajib diisi.',
  completeTheFormToPolicyCreated: 'Lengkapi formulir di bawah ini untuk merincikan detil dari polis yang akan dibuat. Bagian yang diberi tanda bintang (*) wajib diisi.',
  renewalListIsEmpty: 'Daftar Perpanjangan kosong',
  renewalListAppearHere: 'Daftar polis yang akan di perpanjang akan tampil disini',
  noPolicyList: 'Belum ada polis',
  canNotUsedBecauseOfInitialBalance: '*Tidak dapat digunakan karena Saldo Awal',
  makeSureThePhotoYouUploadClear: 'Pastikan foto yang Anda unggah memiliki kualitas gambar yang jelas dan terang, bagian kertas tidak terpotong pada setiap sisinya.',
  forVehicleMoreThanOneUnitYouCanUse: 'Untuk kendaraan lebih dari 1 (satu) buah, Anda dapat menggunakan formulir untuk',
  claimReportingProcessUtillClaimSurvey: '*Proses lapor klaim sampai survei klaim = 2 hari kerja. Proses survei klaim sampai SPK terbit = 3 hari kerja. SPK terbit sampai reparasi selesai = 2 hari kerja',
  newPolicyNewCar: 'Polis baru: Mobil baru',
  policyStartPeriod: 'Periode Mulai Polis',
  activePolicyEndDate: 'Periode Akhir Polis Aktif',
  newPolicyWithUsedCar: 'Polis baru: Mobil bekas',
  forOnlyOneVehicleYouCanUse: 'Untuk 1 (satu) buah kendaraan saja, Anda dapat menggunakan Pesanan',
  renewPolicyWithSameProvider: 'Perpanjangan Polis: Dengan provider yang sama',
  validatingVehicleData: 'Kami sedang memvalidasi data kendaraan',
  renewPolicyWithDifferentProvider: 'Perpanjangan Polis: Dengan provider yang berbeda',
  yourKtpMustVerifiedForThePayment: ' KTP Anda harus terverifikasi untuk menggunakan opsi pembayaran oleh Agen',
  chassisNumberPolicyAlreadyExist: 'Nomor rangka yang Anda masukkan sudah memiliki polis aktif. Silakan masukkan nomor rangka lainnya.',
  paymentCanNotBeDoneForTheChassis: 'Pembayaran tidak dapat dilanjutkan. Nomor rangka CHASSIS_NUMBER sudah memiliki polis aktif.',
  policyDateChangeNote: 'Jika tahun periode polis aktif berubah maka sistem akan otomatis mengarahkan Anda ke halaman detail paket untuk menampilkan perhitungan premi terbaru.',
  youCurrentlyHaveNoTransactions: 'Saat ini Anda tidak memiliki transaksi. Tingkatkan transaksi penjualan asuransi Anda dan raih GWP tertinggi.',
  otpTitleTextMessage: 'Masukkan kode OTP yang terkirim ke handphone Anda melalui SMS/WhatsApp.',
  otpNotRecievedMessage: 'Tidak menerima kode OTP?',
  otpNotRecievedTimerMessage: 'Anda dapat meminta ulang kode OTP dalam ',
  badgeComissionInfoContactUs: 'Untuk informasi komisi dan badge silahkan hubungi RM anda',
  noBankAccount: 'Anda tidak memiliki Akun Bank terdaftar',
  registerBank: 'Silahkan daftarkan nomor Rekening Bank atau nomor GoPay Anda',
  withdrawalWarning: 'Anda hanya dapat melakukan transfer ke rekening atas nama Anda sendiri',
  addBankAccount: 'Tambah Rekening Bank',
  addGopay: 'Tambah Gopay',
  noDownline: 'Anda Belum Memiliki Downline',
  noDownlineDesc: 'Ajak keluarga, teman atau saudara Anda untuk menjadi downline Anda agar dapat meningkatkan poin Anda!',

  // Agent 2.0
  goodMorning: 'Selamat pagi',
  goodAfterNoon: 'Selamat siang',
  goodAfterNoon2: 'Selamat sore',
  goodNight: 'Selamat Malam',
  ourServices: 'Layanan Kami',
  canNotReachAgentName: 'Tidak dapat menghubungi XXX?',
  contactOurCustomerService: 'Hubungi Customer Service kami.',
  columnsMustBeFilled: 'kolom harus diisi.',
  insuranceSpecifications: 'Spesifikasi Asuransi',
  vehicleCriteria: 'Kriteria Kendaraan',
  comprehensive: 'Komprehensif',
  comprehensiveProtection: 'Perlindungan menyeluruh.',
  tlo: 'TLO',
  personalOperations: 'Personal/Operasional Kantor',
  commercial: 'Komersil',
  moreOptions: 'Pilihan Lainnya',
  downloadThePdf: 'Unduh PDF',
  selectTime: 'Pilih Jam',
  makeProtection: 'Buat Proteksi',
  makeCarProtectionAgain: 'Buat Proteksi Mobil Lagi',
  createMoreProtection: 'Buat Proteksi Lainnya',
  additionalFeesApply: 'Berlaku biaya tambahan.',
  totalMaxAmountForAccessories: 'Total Harga Maksimum Aksesoris : ',
  selectedAdditionalCoverage: 'Perlindungan Tambahan Yang Dipilih',
  policyYearIsOneYear: 'Periode polis adalah 1 (satu) tahun.',
  yourOrderWillProceedAfterPaymentReceived: 'Pesanan Anda akan kami proses setelah pembayaran diterima.',
  selectThisOptionIfYourVehicleModifications: 'Pilih opsi ini jika kendaraan Anda terdapat modifikasi.',
  provideProtectionFromRiskOfLoss: 'Memberikan perlindungan pada mobil dari risiko kehilangan.',
  pleaseFillTheFormToGetBestPremium: 'Silahkan isi data dengan benar untuk mendapatkan harga premi yang sesuai dengan kriteria yang ingin diasuransikan',
  weCouldNotFoundItem: 'Kami tidak dapat menemukan apa yang Anda cari. Silakan mencoba kembali.',
  youCanChangeThePrice: 'Anda dapat merubah harga jika tidak sama dengan Binder',
  byCheckingThisYouAcceptTheOffers: 'Dengan mencentang kotak ini, anda setuju bahwa anda  langsung menyutujui penawaran yang diterbitkan ketika isi penawaran sama dengan yang diajukan sebelumnya',
  policyIssuanceWillProcess: 'Penerbitan polis akan diproses melalui kantor pusat/cabang yang dipilih.',
  selectOptionIfYouWantAdditionalCoverage: 'Pilih opsi ini jika ingin mencantumkan perlindungan tambahan pilihan.',
  selectOptionIfVehicleHaveCongenitalDefect: 'Pilih opsi ini jika kendaraan Anda memiliki cacat bawaan.',
  selectThisOptionIfYouWantHardCopy: 'Pilih opsi ini jika Anda ingin menerima polis secara fisik',
  congenitalDamagePhoto: 'Foto Cacat Bawaan',
  selectTransactionType: 'Pilih Jenis Transaksi',
  payLaterTerms: 'Syarat dan Ketentuan PayLater',
  PayLaterTerm1: 'Setiap premi yang jatuh tempo harus dibayarkan oleh penanggung dan diterima oleh PT PasarPolis Insurance Broker secara penuh dalam kurun waktu 60 (enam puluh) hari sejak tanggal dimulainya pertanggungan berdasarkan polis.',
  payLaterTerm2: 'Jika dalam waktu yang telah ditentukan tidak ada pembayaran secara penuh, maka pertanggungan berdasarkan polis ini akan dianggap telah berakhir dari berakhirnya masa tenggang premi tersebut, dan penanggung akan dibebaskan dari semua kewajiban yang terkait dengan polis ini.',
  additionalFeesApplyForCopy: 'Berlaku biaya tambahan sebesar XXXX',
  activitiesThatNeedToCompleted: 'Aktivitas yang perlu diselesaikan.',
  doYouWantToCloseQuoteProcess: 'Apakah Anda ingin menutup proses pembuatan quotation?',
  savedDraftQuoteNotes: 'Sistem akan secara otomatis menyimpan proses terakhir yang telah Anda capai agar Anda dapat melanjutkannya di lain waktu.',
  newAgentBadgeInfo: 'Yuk jual produk asuransi dan raih GWP hingga Rp75.000.000,- untuk naik level. Semakin tinggi level, semakin besar benefit yang didapat! ',
  yourOrderWillProcessWithInNDays: 'Pesanan akan kami proses dalam (XXX) hari kerja. Rincian polis ini akan kami kirimkan melalui email yang Anda daftarkan.',
  yourPaymentGetProcessed: 'Pembayaran Anda sedang diproses. Anda akan mendapatkan informasi terbaru melalui SMS dan Email (maksimal 1x24 jam).',
  normalToPayLaterChangesInfo: 'Perubahan transaksi Normal ke transaksi PayLater bersifat permanen, dan tidak dapat diubah kembali menjadi transaksi Normal.',
  canNotPaymentProofForThePaymentUploaded: 'Tidak dapat diproses. Bukti bayar dari pengajuan polis sudah diupload.',
  claimStatus: 'Status Klaim',
  claimCompleted: 'Klaim Selesai',
  claimComplete: 'CLAIM COMPLETE. ',
  claimNumber: 'Claim Number',
  claimCompleteMessage: 'Thank you for using PasarPolis claim portal. If you have any questions, please contact our customer service at (+62) 21 5089 0580',
  backToPasarpolis: 'KEMBALI KE PASARPOLIS',
  dataProfile: 'Data Profil',
  notCompletedShippingAddress: 'Anda belum melengkapi data Alamat Pengiriman',
  notCompletedKTPNumberData: 'Anda belum melengkapi data Nomor KTP',
  notCompletedNPWPData: 'Anda belum melengkapi data Nomor NPWP',
  verifyYourEmail: 'Verifikasi Email Anda',
  reUpload: 'UNGGAH ULANG',
  uploadKTP: 'UNGGAH FOTO KTP',
  uploadNPWP: 'UNGGAH FOTO NPWP',
  see: 'LIHAT',
  changeKTPNumber: 'Ubah Nomor KTP',
  changeNPWPNumber: 'Ubah Nomor NPWP',
  changeFullName: 'Ubah Nama Lengkap',
  saveData: 'Simpan Data',
  dataYouEnteredIsCorrect: 'Apakah Anda yakin data yang telah Anda isikan sudah benar?',
  performance: 'Performa',
  directory: 'Direktori',
  gwpPerformance: 'Perfoma GWP',
  partnerWorkShopDirectory: 'Direktori Bengkel Rekanan',
  customerDirectory: 'Direktori Pelanggan',
  customerService: 'Customer Service',
  appSetting: 'Pengaturan Aplikasi',
  arrangement: 'Pengaturan',
  policyRejected: 'Polis Ditolak',
  policyReasonRejected: 'Alasan Polis Ditolak',
  policyReasonCancellation: 'Alasan Polis Dibatalkan',
  policyCancelled: 'Polis Dibatalkan',
  yourKTPRejectedGoToProfilePage: (type) => `${type} Anda Ditolak. Silakan ke menu profile saya untuk memperbaiki data.`,
  wait30MinsToChangePhoneNo: 'Tunggu 30 menit, untuk mengganti nomor',
  phoneNo: 'Nomor Handphone',
  mitraId: 'ID Mitra',
  registeredFullName: 'Nama Lengkap Terdaftar',
  newFullName: 'Nama Lengkap Baru',
  newMobileNumber: 'Nomor Handphone Baru',
  registeredMobileNumber: 'Nomor Handphone Terdaftar',
  registeredEmailAddress: 'Alamat Email Terdaftar',
  please: 'Silakan',
  stored: 'TERSIMPAN',
  canChangeNumberAfter6Months: 'Anda dapat mengganti nomor handphone setelah 6 bulan dari pembaruan terakhir.',
  bankDeclaration: 'Persetujuan Deklarasi Bank',
  paymentPendingStatus: 'Menunggu pembayaran',
  paymentFailedStatus: 'Pembayaran gagal',
  expiredStatus: 'KADALUWARSA',
  yesDelete: 'YA, HAPUS',
  deleteNotification: 'Hapus Notifikasi',
  sureYouWantToDeleteNotification: 'Apakah Anda yakin ingin menghapus notifikasi ini?',
  updateYourApp: 'Perbarui Aplikasi Anda!',
  msgForAppUpdate: 'Kami telah memperbaiki beberapa bug dan menambah banyak fitur baru  untuk membuat pengalaman Anda saat menggunakan aplikasi PasarPolis Mitra lebih nyaman, mudah dan cepat. Saatnya untuk memperbarui aplikasi PasarPolis Mitra Anda! ',
  updateNow: 'perbarui sekarang',
  agentNameMismatch: 'Maaf, nama yang anda daftarkan tidak sesuai dengan nama KTP anda. Silahkan masukan nama yang terdaftar di KTP anda',
  holderNameMismatch: 'Maaf, nama yang anda daftarkan tidak sesuai dengan nama KTP anda. Silahkan masukan akun bank yang teregister atas nama anda',
  duplicateBank: 'Maaf, Akun bank ini sudah terdaftar sebelumnya. Silahkan masukan akun bank yang lain',
  close: 'Tutup',

  // Killian
  currencyType: 'Mata Uang',
  currencyChangeDetails: 'Rincian Perubahan Mata Uang',
  applyEndorsement: 'Ajukan Endorsement',
  createEndorsement: 'Buat Endorsement',
  endorsementReqSentConfirm: 'Konfirmasi Pengajuan',
  endorsementRequirements: 'Perubahan yang diperlukan dalam Endorsement',
  effectiveDate: 'Tanggal Efektif',
  endorsementReason: 'Alasan Pengajuan Endorsement',
  endorsementRemark: 'Apabila polis memiliki pengajuan endorsement lebih dari satu, maka akan diproses bertahap sesuai tanggal pengajuan endorsement.',
  otherLengthErrorMsg: 'Panjangnya minimal 20 karakter dan maksimal 300 karakter',
  endorsementReqConfirmContent: 'Apakah Anda yakin data yang diisi sudah sesuai?',
  policyNotFound: 'Polis Tidak ditemukan!',
  policyStatusInvalid: 'Status Polis Tidak Valid!',
  failedGetForm: 'Gagal memuat formulir!',
  endorsementReqRequestSuccess: 'Pengajuan Endorsement Berhasil',
  endorsementReqRequestSuccessContent: 'Selanjutnya akan diteruskan ke pihak asuransi. Silakan pantau perkembangannya melalui aplikasi.',
  endorsementReqSuccessButton: 'Kembali ke Detil Transaksi',
  goToDashboard: 'Buka Dasbor',
  goToTransactonDetail: 'Buka Detail Transaksi',
  reloadPage: 'Klik di sini untuk memuat ulang!',
  activateCoinsurance: 'Aktifkan Co-insurance.',
  addNewMember: 'Tambah Member',
  coinsuranceInfoText: 'Total maximum Share adalah 100% untuk total leader & member provider.',
  leaderShareErrorText: 'Bagian pemimpin harus sama atau lebih besar dari bagian anggota tertinggi.',
  leaderProvider: 'Leader Provider (Opsional)',
  memberProvider: 'Member Provider (Opsional)',
  sharePercent: '% Share (Opsional)',
  noProviderError: 'Silakan Masukkan Setidaknya satu Penyedia',
  activateCoinsuranceModalText: 'Sebuah pertanggungan yang dilaksanakan secara bersama-sama atas suatu objek asuransi tertentu yang biasanya berjumlah besar dan dibutuhkan partisipasi dari beberapa perusahaan asuransi lain untuk andil dalam bagian pertanggungan.',
  sharePercentTitle: '% Share',
  allProducts: 'Semua Produk',
  clearSelections: 'Hapus Pilihan',
  lossRecord: 'Loss Record',
  waitingVerification: 'Sedang Dalam Proses Verifikasi',
  waitingPaymentMethod: 'Anda akan dapat memilih metode pembayaran setelah polis disetujui oleh Pihak Asuransi. Segera lakukan pembayaran setelah polis anda disetujui.',
  newwaitingPaymentMethod: 'Setelah polis Anda disetujui, kami akan memberitahukan agen agar melakukan pembayaran untuk menyelesaikan proses pembelian asuransi Anda.',
  newwaitingPaymentMethod2: 'Butuh bantuan? Silakan menghubungi Customer Service kami melalui informasi kontak di bawah ini.',
  cashAdvanceAmount: 'Cash Advance Amount',
  gwp: 'Gross Written Premium',
  chooseMonth: 'Pilih Bulan',
  show: 'Tampilkan',
  startTrip: 'Mulai Perjalanan',
  endTrip: 'Akhir Perjalanan',
  partner: 'Pasangan',
  mainTraveller: 'Traveler Utama',
  noOptions: 'No Options',
  traveAddressDetail: 'Pertanyaan dan pengaduan dapat diajukan melalui: Email: cs@tap-insure.com | Hotline: 021-50847128 Office: One PacificPlace, lt. 10, SCBD, Jakarta Selatan',
  customerServiceBahasa: 'Layanan Pelanggan',
  premiumTravelInsurance: 'Premier Travel Insurance',
  premiumTravelInsuranceDetails: 'TAP Travel merupakan program kerjasama antara PT Pasarpolis Insurance Broker dan PT Asuransi Untuk Semua. PT Pasarpolis Insurance Broker dan PT Asuransi Untuk Semua terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK).',
  totalTransaction: 'Total Transaksi',
  totalTransactionDetail: 'Transaction Detail',
  backToInformation: 'KEMBALI KE KONFIRMASI',
  register: 'Pendaftaran',
  nameShouldContain2CharacterAtleast: 'Kolom nama harus menggunakan kombinasi Alfanumerik + karakter khusus (!, @, &, %, etc), minimal memiliki dua karakter.',
  insurance: 'Asuransi',
  productInsurance: 'Produk Asuransi',
  typeInsurance: 'Jenis Asuransi',
  typeTrip: 'Tipe Perjalanan',
  destination: 'Tujuan',
  startProtection: 'Awal Perlindungan',
  endProtection: 'Akhir Perlindungan',
  typeProtection: 'Jenis Perlindungan',
  numberCovered: 'Jumlah Tertanggung',
  orderDate: 'Tanggal Pemesanan',
  orderTime: 'Waktu Pemesanan',
  tapTravel: 'TAP Travel',
  OjkText: 'PT PasarPolis Insurance Broker (PPIB) berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK), dengan nomor KEP-19/NB.1/2021. PPIB juga anggota dari Asosiasi Perusahaan Pialang Asuransi dan Reasuransi Indonesia (APPARINDO) dengan nomor keanggotaan No: 252-2019/APPARINDO/2020.',
  additionalInsured: 'Tertanggung Tambahan',
  additionalInfoEmailField: 'Semua informasi polis akan dikirimkan ke email ini',
  additionalInfoPhoneField: 'Pelanggan dapat melakukan klaim melalui aplikasi Tap Insure menggunakan nomor yang terdaftar',
  extendedWarranty: 'Extended Warranty',
  checkPremium: 'Cek Harga Premi',
  emailAdditionalInfoEW: 'Diperlukan untuk pengiriman polis detail',
  sentLink: 'Tautan Terkirim',
  otherOptions: 'Pilihan Lainnya',
  qsText: 'Tautan penawaran asuransi telah berhasil terkirim ke nomor Whatsapp dan Email terdaftar.'
}

export const ERROR_LANG = {
  incorrectAmountEntered: 'Jumlah yang dimasukkan salah',
  withdrawalMax51million: 'Penarikan saldo maksimum Rp 51 juta',
  gopayWithdrawlMax10million: 'Penarikan saldo ke akun Gopay maksimum Rp 10 juta',
  pleaseProvideValidData: 'Harap berikan XXX yang valid',
  maximumSumInsuredForAccessories: 'Maksimum total nilai pertanggungan aksesoris standar 10% dari harga kendaraan',
  agentAddressIsEmpty: 'Opsi alamat agent yang dipilih masih kosong',
  pleaseSelectTheProductForInsurance: 'Silakan Pilih Produk untuk asuransi',
  enterAmountNotInRange: 'Masukkan jumlah tidak dalam kisaran',
  disclaimerError: 'Silakan menyetujui syarat dan ketentuan untuk melanjutkan',
  policyHolderMustBeWorking: 'Pemegang polis harus sudah bekerja',
  occupationTypeShouldNotBeHighRisk: 'Jenis Pekerjaan tidak boleh berisiko tinggi',
  requiredField: 'Format tidak sesuai',
  incorrectFormat: 'Format Salah',
  postalCodeError: 'Kode Pos harus terdiri dari 5 angka',
  atLeastOnePhotoRequired: 'Minimal 1 Foto Terunggah',
  nameAndDobBeneficiaryError: 'Nama & Tanggal Lahir Tertanggung Tidak Boleh Sama Dengan Ahli Waris',
  referralCodeRequired: 'Diperlukan kode rujukan',
  shareOptionNotSupport: 'Maaf, opsi Bagikan tidak mendukung',
  pointBalanceInsufficient: 'Saldo Poin tidak cukup',
  nameMustBeginWithLetter: ' Nama harus dimulai dengan huruf',
  nameMayContain5digit: 'Nama hanya bisa terdiri dari 5 angk',
  nameValidationError: 'Nama harus diawali dengan alfabet dan hanya boleh terdiri dari alfabet, angka, titik, garis bawah dan garis miring',
  nameErrorRequied: 'Min 3 dan max 60 character',
  phoneRequiredError: 'Format nomor HP tidak sesuai',
  emailRequiredError: 'Format email tidak sesuai',
  maxDiscountError: 'Jumlah diskon yang anda berikan melebihi dari maksimal komisi yang didapatkan',
  customerHaveAlreadyPolicy: 'Tertanggung sudah memiliki 1 polis aktif',
  somethingWentWrong: 'Ada yang salah, Harap coba lagi!',
  pleaseAcceptTermCondition: 'Harap terima Syarat dan Ketentuan',
  pleaseSelectPaymentMethod: 'Silakan pilih metode pembayaran',
  covidPolicyHolderAgeError: 'Umur harus antara 18 tahun hingga 80 tahun',
  lippoHealthPolicyAgeError: 'Umur harus antara 17 tahun hingga 55 tahun',
  jiwaPolicyHolderAgeError: 'Umur harus antara 17 tahun hingga 60 tahun',
  beneficiaryAgeError: 'Usia tertanggung tidak boleh melebihi 50 thn',
  motorCycleHolderAgeError: 'Usia pemegang polis minimum harus berusia 17 tahun dan maximum berusia 60 tahun',
  invalidDateFormat: 'Silakan masukkan tanggal yang valid',
  referralCodeInValid: 'Kode rujukan tidak valid',
  policyDeliveryAddressError: 'Alamat pengiriman polis tidak boleh kosong',
  policyAddressMinimumChar: 'Alamat harus lebih jelas, minimum 20 karakter',
  policyAddressMinimum10Char: 'Alamat harus lebih jelas, minimum 10 karakter',
  addressBetween10and100char: 'Alamat harus diisi dengan 10 sampai 100 karakter',
  sorryApplicationWasCancelled: 'Mohon maaf, pengajuan polis Anda sudah tidak valid',
  theInsuredAlreadyHaveFourPolicies: 'Tertanggung sudah memiliki 4 aktif polis',
  insuredMotorBikeHaveOneActivePolicy: 'Sepeda motor tertanggung sudah memiliki 1 aktif polis',
  errorForWithdrawalWithoutPolicy: 'Silakan jual setidaknya satu polis Asuransi untuk menebus saldo dompet Anda',
  invalidPostalCode: 'Kode pos tidak sesuai',
  tsiLimitExceeded: 'TSI untuk nomor perjalanan travel_no sudah melebihi batas maksimum 2 triliun di tanggal departure_date',
  policyHolderClickError: 'Tidak dapat memilih Diri Sendiri karena berusia > 50 tahun. Silakan asuransikan anggota keluarga Anda (Anda akan menjadi Pemegang Polis, tidak menjadi Tertanggung)',
  youCannotInsureYourSelfError: 'Anda tidak dapat mengasuransikan diri sendiri jika usia pemegang polis lebih dari 50 tahun',
  errorMsgForBlackListUserSupport: 'Maaf, permintaan Anda untuk anggota Tertanggung ini tidak dapat diproses. Untuk melanjutkan, silakan hubungi tim Layanan Pelanggan kami. 021 5089 0580',
  userIsBlackListed: 'Mohon maaf permintaan Anda untuk anggota tertanggung ini tidak dapat diproses. Untuk melanjutkan, silakan hapus anggota tersebut atau hubungi tim Customer Support kami.',
  errorOnAgainSubmitDoc: 'Anda sudah pernah mengirimkan dokumen. Harap tunggu persetujuan dari PasarPolis',
  enterPolicyAdditionInfo: 'Harap Masukkan Informasi Tambahan',
  pleaseSelectPaidTo: 'Silakan pilih Dibayar Ke',
  pleaseSelectPaymentDate: 'Silakan pilih Tanggal Pembayaran',
  pleaseEnterPaymentAmount: 'Harap Masukkan Jumlah Pembayaran',
  pleaseUploadPhotoOfProof: 'Harap Unggah Foto Bukti Pembayaran',
  emptyOfflinePolicy: 'Kebijakan Offline tidak ditemukan',
  pleaseProvideAllRequiredData: 'Harap berikan semua data yang diperlukan',
  pleaseEnterTodayDateOrPreviousDate: 'Harap masukkan tanggal hari ini atau tanggal sebelumnya',
  pleaseProvideYourAnswer: 'Harap berikan jawaban Anda untuk pertanyaan yang diperlukan',
  noOfAgentAlreadyOnNextBadge: 'AGENT_COUNT Agen sudah berada posisi NEXT_BADGE. Kumpulkan REMAINING_BADGE_AMOUNT lagi untuk mengejar ketinggalan.', // REMAINING_BADGE_AMOUNT need to replace,
  min20max500Character: 'Min 20 dan max 500 character',
  wrongBankAccountName: 'Nama pemilik rekening tidak sama dengan nama Mitra yang terdaftar. Silakan masukan nama pemilik rekening dengan nama Mitra yang terdaftar.',
  lossRecordDateMustBeIn3To5Yrs: 'Tanggal loss record harus dalam 3 sampai 5 tahun terakhir',
  machineNumberValidation: 'Masukkan nomor mesin 5 sampai 21 karakter',
  chassisValidationError: 'Masukkan nomor rangka 8 sampai 20 karakter',
  vehicleAlreadyHasAnActivePolicy: 'Kendaraan sudah memiliki polis aktif di PasarPolis',
  lossRecordValidationError: 'Loss Record dan Jumlah Klaim tidak boleh kosong',
  pleaseEnterPaymentInvoice: 'Mohon input jumlah yang harus dibayarkan, sesuai dengan nominal yang tercantum di invoice',
  minimum8digits: 'Min 8 digit',
  minimum5digits: 'Min 5 digit',
  maximum8digits: 'Max 8 digit',
  maximum4digits: 'Max 4 digit',

  // travel related error
  getTravelDetailErrorText: 'Terjadi masalah dalam mendapatkan detail perjalanan. Silakan coba beberapa saat lagi.',
  errorWhileUpdating: 'Ada masalah dalam memperbarui detail. Silakan coba beberapa saat lagi.',
  ktpError: 'Nomor KTP harus 16 digit',
  ageShouldBeBetween17and70: 'Usia harus di antara 17 dan 70 tahun pada hari Perjalanan.',
  shouldBeYoungerThan70: 'Usia harus lebih muda dari 70 tahun pada hari Perjalanan.',
  phoneNumberError: 'Format nomor telepon tidak sesuai (contoh: +6281217075225)',
  ktpErrorBetween11And12: 'Harus antara 11 dan 12 karakter alfanumerik',
  ktpErrorOf9CharactersInternational: 'Harus terdiri dari 9 karakter saja',
  tryAfterSomeTime: 'Ada yang salah. Coba setelah beberapa waktu',
  phoneNumberNotStartsWith62: 'Format nomor telepon salah, mohon gunakan format +62/62/08',
  max15DigitAllowed: 'Mohon memasukkan maksimal 15 digit',
  max14DigitAllowed: 'Mohon memasukkan maksimal 14 digit',
  shoudldBeMoreThan3months: 'Seharusnya lebih dari 3 bulan',
  passportCorrectError: 'Silakan masukkan nomor paspor yang benar',
  maxChildLimit: 'Lebih dari tiga anak tidak diperbolehkan',
  maxSpouseLimit: 'Lebih dari satu pasangan tidak diperbolehkan'
}

export const DASHBOARD_INTRO_LANG = {
  back: 'KEMBALI',
  homeBtn: 'Halaman Depan(Beranda)',
  transactionBtn: 'Halaman Polis Terjual',
  walletBtn: 'Halaman komisi Anda',
  profileBtn: 'Halaman Prfil Anda',
  yourEarningsDisplayHere: 'Penghasilan Anda akan ditampilkan di sini. Klik <strong>Tarik Saldo</strong> untuk mencairkan komisi ke rekening bank Anda',
  clickToRecommentCovid: 'Klik untuk merekomendasikan Asuransi COVID dan mendapatkan komisi',
  clickOnFollowingForFunctions: 'Klik ikon berikut untuk berbagai fungsi:',
  getAnswersForQuestions: 'Dapatkan jawaban untuk pertanyaan-pertanyaan Anda seputar Mitra',
  clickOnBellIcon: 'Klik ikon <b>Bel</b> untuk melihat notifikasi'
}

export const COVID_PKG_INTRO_LANG = {
  covidInsuranceDetails: 'Rincian Asuransi COVID',
  packgeName: 'Nama Paket',
  numberOfDeathBenefits: 'Jumlah Manfaat Kematian',
  totalBenefits: 'Jumlah Manfaat Rawat Inap',
  premium: 'Premi Polis',
  clickHereToRecommendInsurance: 'Klik disini untuk merekomendasikan Asuransi COVID'
}

export const COVID_SHARING_INTRO_LANG = {
  enterCustomerMobileNumberToShare: 'Masukkan nomor ponsel pelanggan Anda yang akan dikirimkan link referral COVID',
  sendSmsLinkReferral: 'Kirim SMS link referral',
  sendWhatsAppLinkReferral: 'Kirim WhatsApp link referral',
  copyReferralLink: 'Salin link referral',
  shareReferralLinkOnWhatsApp: 'Bagikan link referral di WhatsApp'
}

export const COVID_SALES_GUIDE_LANG = {
  landingPageTitle: 'Anda (Mitra) masuk aplikasi dan pilih Beli Proteksi <span><b>Asuransi Kesehatan</b></span>',
  pkgListTilt: 'Klik tombol <span><b>Tawarkan Asuransi Covid</b></span> yang terletak pada bagian bawah',
  shareOnSms: 'Bagikan Link Rujukan melalui <span><b>Whatsapp</b></span> atau melalui <span><b>SMS</b></span> dengan masukkan nomor telepon pelanggan Anda',
  shareSmsOnWhatsAPP: 'Pelanggan akan menerima <span><b>Link Rujukan</b></span> melalui Whatsapp atau SMS',
  customerSidePkgList: 'Pelanggan akan memilih <span><b>paket asuransi</b></span> yang sesuai kebutuhannya',
  policyHolderTitle: 'Pelanggan diminta untuk mengisi beberapa <span><b>data diri</b></span> yang diperlukan',
  paymentScreenTitle: 'Pelanggan diminta untuk melakukan <span><b>pembayaran</b></span> sesuai Total Premi',
  paymentSuccessTitle: 'Transaksi selesai! Dapatkan <span><b>komisi menarik</b></span> untuk setiap penjualan yang berhasil'
}

export const DOWNTIME_MSG = 'Mohon maaf Mitra, PP Mitra akan mengalami gangguan dikarenakan ada pemeliharaan sistem pada tanggal 17 Mei 2022, 02:30 - 06:30 WIB.'

export const UNAUTHORIZED_ACCESS_LANG = {
  youCannotProcess: 'Anda Tidak Dapat Melanjutkan Proses',
  login: 'Masuk',
  youDonNotHaveAccessThePage: 'Anda tidak mempunyai akses untuk pergi ke halaman ini. Silakan login dari akun agen untuk memberikan izin dan menyelesaikan proses.'
}
